import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const PlayerDetailSection = () => {
  const sections = useSelector((state) => state.players.detailSection);
  const selectedSection = useSelector((state) => state.players.selectedSection);
  const location = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();

  return (
    <>
      {location.pathname.includes('traders') && id ? (
        <div className="flex flex-row overflow-auto max-w-none w-full lg:ml-[12px]">
          {sections.map((section, idx) => (
            <button
              key={section}
              onClick={() => {
                if (section !== selectedSection) {
                  let url = `/traders/${id}?player_section=${section}`;
                  navigate(url);
                }
              }}
              className={`${
                selectedSection === section ? 'opacity-100' : 'opacity-20'
              } bg-green text-ivory border-r-[2px] py-[12px] px-[16px] font-poppins-semibold box-shadow-green min-w-max`}
            >
              {section}
            </button>
          ))}
        </div>
      ) : null}
    </>
  );
};

export default PlayerDetailSection;
