import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedSection: 'season',
  sections: [
    {
      name: 'Season Information',
      section: 'season',
    },
    {
      name: 'Reward & Announcement',
      section: 'reward',
    },
    {
      name: 'Winners',
      section: 'winners',
    },
  ],
};

export const seasonSection = createSlice({
  name: 'seasonSection',
  initialState,
  reducers: {
    setSelectedSession: (state, action) => {
      state.selectedSection = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setSelectedSession } = seasonSection.actions;

export default seasonSection.reducer;
