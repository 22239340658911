import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setPageData } from '../slices/binarySlice';
import { TableBody, TableHead, TableRow } from '@mui/material';
import LayoutAdmin from '../components/Layout/LayoutAdmin';
import StyledTableCell from '../components/Table/StyledTableCell';
import StyledTableContainer from '../components/Table/StyledTableContainer';
import StyledTable from '../components/Table/StyledTable';
import Loading from '../components/Common/Loading';
import Pagination from '../components/Table/Pagination';
import DataLimit from '../components/Table/DataLimit';
import { fetchOptionList } from '../actions/binary';

const LotteryPage = () => {
  const { isLoading, optionList, pageData, totalPages } = useSelector(
    (state) => state.binary
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const goToOptionDetail = (seasonID) => {
    navigate(`/option/${seasonID}`);
  };

  const handlePageChange = (ev) => {
    if (ev.selected !== pageData.page) {
      dispatch(setPageData({ page: ev.selected }));
    }
  };

  useEffect(() => {
    dispatch(fetchOptionList(pageData));
  }, [dispatch, pageData]);

  return (
    <>
      {isLoading ? <Loading /> : null}
      <LayoutAdmin
        pageTitle="Trading Game"
        pageSubtitle="List of Traded Currency"
      >
        <div className="flex lg:flex-row flex-col lg:justify-between lg:items-center">
          <DataLimit initialLimit={pageData.dataLimit} setter={setPageData} />
          <button
            onClick={() => navigate('/option/new')}
            className="px-[16px] py-[8px] bg-black-100 text-white rounded-[20px] font-poppins-semibold ml-auto block"
          >
            Add Currency
          </button>
        </div>
        <div className="mt-[16px]">
          {optionList && optionList.length && !isLoading ? (
            <div>
              <StyledTableContainer>
                <StyledTable>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Currency/USDT</StyledTableCell>
                      <StyledTableCell>Icon</StyledTableCell>
                      <StyledTableCell>Status</StyledTableCell>
                      <StyledTableCell>Time Interval</StyledTableCell>
                      <StyledTableCell>Latest Time to Join</StyledTableCell>
                      <StyledTableCell>
                        Losing Pot Percentage for Winners
                      </StyledTableCell>
                      <StyledTableCell>Referral Percentage</StyledTableCell>
                      <StyledTableCell>Associate Percentage</StyledTableCell>
                      <StyledTableCell>Executive Percentage</StyledTableCell>
                      <StyledTableCell sx={{ textAlign: 'center' }}>
                        Action
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {optionList.map((option) => (
                      <TableRow key={option._id}>
                        <StyledTableCell>
                          {option.coin_info.name}
                        </StyledTableCell>
                        <StyledTableCell>
                          <div className="w-[30px] h-[30px] rounded-full">
                            <img
                              src={option.icon}
                              alt={`${option.coin_info.name}-icon`}
                              className="w-full h-full object-cover rounded-full"
                            />
                          </div>
                        </StyledTableCell>
                        <StyledTableCell>{option.status}</StyledTableCell>
                        <StyledTableCell>
                          {option.time_interval.value}{' '}
                          {option.time_interval.unit}
                        </StyledTableCell>
                        <StyledTableCell>
                          {option.latest_trade_time.value}{' '}
                          {option.latest_trade_time.unit}
                        </StyledTableCell>
                        <StyledTableCell>
                          {option.losing_pot_percentage}%
                        </StyledTableCell>
                        <StyledTableCell>
                          {option.referral_percentage}%
                        </StyledTableCell>
                        <StyledTableCell>
                          {option.associate_percentage}%
                        </StyledTableCell>
                        <StyledTableCell>
                          {option.executive_percentage}%
                        </StyledTableCell>
                        <StyledTableCell>
                          <button
                            onClick={() => goToOptionDetail(option._id)}
                            className="font-poppins-semibold py-[4px] px-[16px] border rounded-[20px] border-black-100"
                          >
                            Edit/Detail
                          </button>
                        </StyledTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </StyledTable>
              </StyledTableContainer>
              <Pagination
                totalPages={totalPages}
                pageData={pageData}
                onChange={handlePageChange}
              />
            </div>
          ) : (
            <h3 className="text-center mt-[5rem] text-base">
              No currency data found
            </h3>
          )}
        </div>
      </LayoutAdmin>
    </>
  );
};

export default LotteryPage;
