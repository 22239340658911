import { enqueueSnackbar } from 'notistack';
import AdminCurrentLC from '../Admin/AdminCurrentLC';
import Modal from '../Modal/Modal';

const TopUpLCModal = ({ closeModal, walletAddress }) => {
  return (
    <Modal closeModal={closeModal} title="Top Up CKU">
      <AdminCurrentLC />
      <div className="flex flex-col">
        <h4 className="text-sm font-poppins-bold">LuckyCat's Wallet Address</h4>
        <div className="flex flex-row justify-between mt-[4px]">
          <div className="w-[108px] h-[108px]">
            <ion-icon
              name="qr-code-outline"
              style={{ width: '100%', height: '100%' }}
            ></ion-icon>
          </div>
          <div className="flex flex-col w-[50%]">
            <p className="text-sm">{walletAddress}</p>
            <button
              type="button"
              onClick={() => {
                navigator.clipboard.writeText(walletAddress);
                enqueueSnackbar('Sucessfully copied wallet address', {
                  variant: 'success',
                });
              }}
              className="bg-white border w-full py-[8px] border-black-100 rounded-lg font-poppins-semibold mt-[8px]"
            >
              Copy Address
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default TopUpLCModal;
