import { createSlice } from '@reduxjs/toolkit';
import {
  checkReceiverName,
  sendCoinExternally,
  sendCoinInternally,
  sendCoinOTP,
} from '../actions/coins';

const initialState = {
  receiverName: '',
  isLoading: false,
};

export const coins = createSlice({
  name: 'coins',
  initialState,
  reducers: {
    resetReceiver: (state, action) => {
      state.receiverName = initialState.receiverName;
    },
  },
  extraReducers: {
    [checkReceiverName.pending]: (state) => {
      state.isLoading = true;
    },
    [checkReceiverName.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.receiverName = payload.name;
    },
    [checkReceiverName.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [sendCoinInternally.pending]: (state) => {
      state.isLoading = true;
    },
    [sendCoinInternally.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
    },
    [sendCoinInternally.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [sendCoinExternally.pending]: (state) => {
      state.isLoading = true;
      console.log('rejectedddddd');
    },
    [sendCoinExternally.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
    },
    [sendCoinExternally.rejected]: (state, { payload }) => {
      state.isLoading = false;
      console.log(payload, '===errorpaylod');
    },
    [sendCoinOTP.pending]: (state) => {
      state.isLoading = true;
    },
    [sendCoinOTP.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
    },
    [sendCoinOTP.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { resetReceiver } = coins.actions;

export default coins.reducer;
