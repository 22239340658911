import { useEffect, useState } from 'react';
import ActionButton from '../Button/ActionButton';
import ExchangeWallet from './ExchangeWallet';
import { useCopyToClipboard } from '../../hooks/useCopyToClipboard';
import LCAmount from './LCAmount';
import { useDispatch, useSelector } from 'react-redux';
import { setRefetchWalletList } from '../../slices/walletSlice';
import ActiveWallet from './ActiveWallet';

const GetLC = () => {
  const [modal, setModal] = useState('');
  const { copyText } = useCopyToClipboard();
  const dispatch = useDispatch();
  const { duotrxWalletAddress } = useSelector((state) => state.dashboard);

  useEffect(() => {
    dispatch(setRefetchWalletList(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {modal === 'get_cku' ? <ExchangeWallet closeModal={setModal} /> : null}
      <div className="flex flex-col pb-[16px]">
        <div className="flex flex-col p-[8px] gap-[4px] mb-[16px]">
          <LCAmount section="Get LC" />
          {/* <div>
            <p className="text-sm opacity-50">
              <span className="font-poppins-semibold">Note: </span>Your CKU will
              automatically be converted into LC after a successful transfer.
            </p>
          </div> */}
        </div>
        <div className="flex flex-col bg-light-yellow border border-black-100 p-[8px] mb-[16px]">
          <h3 className="font-poppins-semibold text-sm">
            How to get CKU to play:
          </h3>
          <p className="text-sm ml-[4px]">
            <span className="font-poppins-semibold">1. </span>
            Connect your{' '}
            <span className="font-poppins-semibold text-blue">
              Latoken
            </span>{' '}
            wallet address.
          </p>
          <p className="text-sm ml-[4px]">
            <span className="font-poppins-semibold">2. </span>
            Click <span className="font-poppins-semibold">Get CKU</span>,
            purchase and transfer CKU to Duotrx Wallet Address.
          </p>
        </div>
        <ActiveWallet />
        <div className="flex flex-col mb-[16px]">
          <h3 className="font-poppins-semibold text-sm">
            Duotrx Latoken Wallet Address
          </h3>
          <div
            className="text-sm flex flex-row justify-between bg-light-yellow items-center w-full p-[8px] border border-black-100 cursor-pointer"
            onClick={() =>
              copyText(duotrxWalletAddress, 'Succefully copied email')
            }
          >
            <p className="font-poppins-semibold">{duotrxWalletAddress}</p>
            <button type="button" className="flex ml-[4px]">
              <ion-icon
                name="copy-outline"
                style={{ width: '20px', height: '20px' }}
              ></ion-icon>
            </button>
          </div>
        </div>
        <ActionButton
          title="Get CKU"
          classes="text-lg"
          onClick={() => setModal('get_cku')}
        />
      </div>
    </>
  );
};

export default GetLC;
