import { createSlice } from '@reduxjs/toolkit';
import {
  createSeason,
  deleteSeason,
  editSeasonBanner,
  editSeasonBasicInfo,
  editSeasonSalesPitch,
  editSeasonStatus,
  fetchDrawdownWinnerList,
  fetchSeasonInfo,
  fetchSeasonList,
} from '../actions/season';

const initialState = {
  seasonList: [],
  selectedSeasonInfo: null,
  isLoading: false,
  totalPages: 0,
  winnerPages: 0,
  winnerSummary: null,
  winnerList: [],
  pageData: {
    page: 0,
    search: '',
    dataLimit: 10,
  },
  winnerPageData: {
    page: 0,
    search: '',
  },
};

export const season = createSlice({
  name: 'season',
  initialState,
  reducers: {
    setPageData: (state, action) => {
      state.pageData = { ...state.pageData, ...action.payload };
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setWinnerageData: (state, action) => {
      state.winnerPageData = { ...state.winnerPageData, ...action.payload };
    },
  },
  extraReducers: {
    [fetchSeasonList.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchSeasonList.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.seasonList = payload.lottery_season_list;
      state.totalPages = payload.pages;
    },
    [fetchSeasonList.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [fetchSeasonInfo.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchSeasonInfo.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.selectedSeasonInfo = payload;
    },
    [fetchSeasonInfo.rejected]: (state) => {
      state.isLoading = false;
    },
    [fetchDrawdownWinnerList.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchDrawdownWinnerList.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.winnerList = payload.winner_list;
      state.winnerPages = payload.pages;
      state.winnerSummary = payload.summary;
    },
    [fetchDrawdownWinnerList.rejected]: (state) => {
      state.isLoading = false;
    },
    [createSeason.pending]: (state) => {
      state.isLoading = true;
    },
    [createSeason.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.seasonList = [payload.details, ...state.seasonList];
    },
    [createSeason.rejected]: (state) => {
      state.isLoading = false;
    },
    [editSeasonBasicInfo.pending]: (state) => {
      state.isLoading = true;
    },
    [editSeasonBasicInfo.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      const { details } = payload;
      state.selectedSeasonInfo = details;
    },
    [editSeasonBasicInfo.rejected]: (state) => {
      state.isLoading = false;
    },
    [editSeasonSalesPitch.pending]: (state) => {
      state.isLoading = true;
    },
    [editSeasonSalesPitch.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      const { details } = payload;
      state.selectedSeasonInfo = details;
    },
    [editSeasonSalesPitch.rejected]: (state) => {
      state.isLoading = false;
    },
    [editSeasonBanner.pending]: (state) => {
      state.isLoading = true;
    },
    [editSeasonBanner.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      const { details } = payload;
      state.selectedSeasonInfo = details;
      if (state.seasonList && state.seasonList.length) {
        const index = state.seasonList
          .map((season) => season._id)
          .indexOf(details._id);
        state.seasonList[index] = details;
      }
    },
    [editSeasonBanner.rejected]: (state) => {
      state.isLoading = false;
    },
    [editSeasonStatus.pending]: (state) => {
      state.isLoading = true;
    },
    [editSeasonStatus.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      const { details } = payload;
      state.selectedSeasonInfo = details;
    },
    [editSeasonStatus.rejected]: (state) => {
      state.isLoading = false;
    },
    [deleteSeason.pending]: (state) => {
      state.isLoading = true;
    },
    [deleteSeason.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.seasonList = state.seasonList.filter(
        (season) => season._id !== payload.id
      );
    },
    [deleteSeason.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

export const { setPageData, setWinnerageData, setIsLoading } = season.actions;

export default season.reducer;
