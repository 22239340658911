import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { convToBase64 } from '../../utils/convToBase64';
import { serverAPI } from '../../utils/API';
import { setIsLoading } from '../../slices/imageUploadSlice';
import Loading from './Loading';
import { enqueueSnackbar } from 'notistack';

const ImageUpload = ({ type, name, setImage, fileToUpload }) => {
  const [file, setFile] = useState(null);
  const isLoading = useSelector((state) => state.imageUpload.isLoading);
  const dispatch = useDispatch();

  const convertBase64ToFile = (base64, name) => {
    var arr = base64.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    var convertedFile = new File(
      [u8arr],
      getFileName(name, mime.split('/')[1]),
      {
        type: mime,
      }
    );
    return convertedFile;
  };

  const getFileName = (name = 'img', extension = 'jpg') => {
    var d = new Date().getDate(),
      m = new Date().getMonth(),
      y = new Date().getFullYear(),
      t = new Date().getTime();
    return `${name}-${m + d + y + t}.${extension}`.toLowerCase();
  };

  const uploadImage = (formData) => {
    dispatch(setIsLoading(true));

    serverAPI
      .post('adminImageUpload', formData)
      .then((res) => {
        if (res) {
          const { data } = res;
          if (data && data.path) setImage(data.path);
        }
      })
      .catch((error) => {
        if (error.response && error.response.data.message) {
          enqueueSnackbar(error.response.data.message, { variant: 'error' });
        } else {
          enqueueSnackbar(error.message, { variant: 'error' });
        }
      })
      .finally(() => {
        setFile(null);
        dispatch(setIsLoading(false));
      });
  };

  useEffect(() => {
    if (file) {
      convToBase64(file).then((res) => {
        const image = convertBase64ToFile(res, name);
        const extension = image.name.split('.');
        const formattedFilename = getFileName(
          name,
          extension[extension.length - 1]
        );

        const formdata = new FormData();
        formdata.append('file', image, formattedFilename);
        formdata.append('imagename', formattedFilename);
        formdata.append('image_type', type);

        uploadImage(formdata);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  useEffect(() => {
    if (fileToUpload && typeof fileToUpload !== 'string') setFile(fileToUpload);
  }, [fileToUpload]);

  const onFileChange = (ev) => {
    if (ev.target.files) setFile(ev.target.files[0]);
  };

  return (
    <>
      {isLoading ? <Loading /> : null}
      <input
        type="file"
        id="image-upload"
        accept="image/*"
        onChange={onFileChange}
        className="hidden"
      />
    </>
  );
};

export default ImageUpload;
