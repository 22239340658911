import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'notistack';
import Modal from './Modal';
import { deleteAdmin } from '../../actions/admin';
import getToastMessage from '../../utils/toastMessage';

const AdminDeleteModal = ({ adminID, closeModal }) => {
  const dispatch = useDispatch();
  const onDeleteAdmin = async () => {
    const done = await dispatch(deleteAdmin({ _id: adminID }));
    if (done) {
      const { message, variant } = getToastMessage(done);
      if (variant === 'success') {
        enqueueSnackbar(message, { variant });
        closeModal(1);
      }
    }
  };

  return (
    <Modal title="Delete Admin" closeModal={closeModal}>
      <h3 className="text-base">Are you certain about deleting this admin?</h3>
      <div className="flex flex-row justify-between items-center mt-[24px]">
        <button
          onClick={() => closeModal(false)}
          type="button"
          className="w-[45%] px-[16px] py-[8px] text-red border rounded-[20px] font-poppins-semibold"
        >
          Cancel
        </button>
        <button
          type="button"
          onClick={onDeleteAdmin}
          className="w-[45%] px-[16px] py-[8px] bg-black-100 text-white border rounded-[20px] font-poppins-semibold"
        >
          Delete
        </button>
      </div>
    </Modal>
  );
};

export default AdminDeleteModal;
