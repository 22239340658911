import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { forgotPasswordStep1 } from '../../actions/auth';
import getToastMessage from '../../utils/toastMessage';
import { enqueueSnackbar } from 'notistack';
import Modal from './Modal';

const ForgotPasswordModal = ({ closeModal }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const dispatch = useDispatch();

  const onSendLink = async (data) => {
    const done = await dispatch(forgotPasswordStep1(data));
    if (done) {
      const { message, variant } = getToastMessage(done);
      if (variant === 'success') {
        enqueueSnackbar(message, { variant });
        closeModal(false);
      }
    }
  };

  return (
    <Modal title="Forgot Password" closeModal={closeModal}>
      <form onSubmit={handleSubmit(onSendLink)}>
        <div className="flex flex-col mb-[18px] w-full">
          <label className="text-sm">Admin Email</label>
          <input
            type="email"
            {...register('email', {
              required: 'Email is required',
              validate: {
                matchPattern: (v) =>
                  /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                  'Not a valid email address',
              },
            })}
            placeholder="Email"
            className="text-sm py-[11px] px-[8px] border border-black-100 bg-white rounded-[6px] outline-0"
          />
          {errors && errors['email'] && (
            <p className="text-xs text-red">{errors['email'].message}</p>
          )}
        </div>
        <p className="text-sm my-[24px]">
          Forget password link will be sent to registered email.
        </p>
        <button
          type="submit"
          className="bg-black-100 text-white text-base font-poppins-medium w-full py-[8px] rounded-[20px] mt-[12px]"
        >
          Send Link
        </button>
      </form>
    </Modal>
  );
};

export default ForgotPasswordModal;
