import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  imageLink: '',
  isLoading: false,
};

export const imageUpload = createSlice({
  name: 'imageUpload',
  initialState,
  reducers: {
    setImageLink: (state, action) => {
      state.imageLink = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setImageLink, setIsLoading } = imageUpload.actions;

export default imageUpload.reducer;
