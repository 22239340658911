import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CKUIcon from '../../assets/imgs/coin.png';
import { thousandSeparator } from '../../utils/math';
import WinningPotInfo from '../Season/WinningPotInfo';
import { getAdminBalance } from '../../actions/dashboard';

const AdminCurrentLC = ({
  type = '',
  textAlign = 'center',
  useLatest = false,
}) => {
  const [showWinpotInfo, setShowWinpotInfo] = useState(false);
  const [selectedInfo, setSelectedInfo] = useState(null);
  const { adminBalance, usdPrice } = useSelector((state) => state.dashboard);
  const { latestDrawdownInfo, selectedDrawdownInfo } = useSelector(
    (state) => state.drawdown
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (type !== 'winning') dispatch(getAdminBalance());
  }, [dispatch, type]);

  useEffect(() => {
    useLatest
      ? setSelectedInfo(latestDrawdownInfo)
      : setSelectedInfo(selectedDrawdownInfo);
  }, [latestDrawdownInfo, selectedDrawdownInfo, useLatest]);

  return (
    <>
      {showWinpotInfo ? (
        <WinningPotInfo closeModal={setShowWinpotInfo} />
      ) : null}
      <div
        className={`flex flex-col ${
          textAlign !== 'center' ? 'text-left' : 'text-center mb-[24px]'
        } pb-[24px] border-b border-b-grey-20 font-poppins-semibold`}
      >
        <div
          className={`flex flex-row items-center ${
            textAlign !== 'center' ? 'justify-start' : 'justify-center'
          }`}
        >
          <h3 className="text-base">
            {type === 'winning'
              ? `Winning Pot Budget`
              : `Admin's Current CKU Balance`}
          </h3>
          {type === 'winning' ? (
            <button
              type="button"
              className="flex"
              onClick={() => setShowWinpotInfo(true)}
            >
              <ion-icon
                name="information-circle-outline"
                style={{
                  width: '16px',
                  height: '16px',
                  marginLeft: '8px',
                }}
              ></ion-icon>
            </button>
          ) : null}
        </div>
        <div
          className={`flex flex-row items-center ${
            textAlign !== 'center' ? 'justify-start' : 'justify-center'
          }`}
        >
          <div className="w-[22px] h-[22px]">
            <img
              src={CKUIcon}
              alt="LC Icon"
              className="w-full h-full object-cover"
            />
          </div>
          <p className="text-lg ml-[4px]">
            {type === 'winning' && selectedInfo
              ? thousandSeparator(selectedInfo.winning_pot_budget)
              : adminBalance
              ? thousandSeparator(adminBalance.cku_balance)
              : 0}
          </p>
        </div>
        <p className="text-sm font-poppins">
          USD{' '}
          {type === 'winning' && selectedInfo
            ? thousandSeparator(selectedInfo.winning_pot_budget * usdPrice)
            : adminBalance
            ? thousandSeparator(adminBalance.cku_balance * usdPrice)
            : 0}
        </p>
      </div>
    </>
  );
};

export default AdminCurrentLC;
