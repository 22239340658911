import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TradeGroupModal from './TradeGroupModal';
import { thousandSeparator } from '../../utils/math';
import { setSelectedTradeGroup } from '../../slices/binarySlice';

const TradeGroupForm = () => {
  const { selectedOptionDetail } = useSelector((state) => state.binary);
  const dispatch = useDispatch();
  const [showGroupModal, setShowGroupModal] = useState(false);

  if (selectedOptionDetail) {
    return (
      <>
        {showGroupModal ? (
          <TradeGroupModal closeModal={setShowGroupModal} />
        ) : null}

        <div className="flex flex-col gap-[16px] mb-[24px]">
          <span className="flex flex-row gap-[16px] items-center">
            <h3 className="text-green font-poppins-semibold text-base">
              Trade Group
            </h3>
            <button
              type="button"
              onClick={() => setShowGroupModal(true)}
              className="w-fit px-[16px] py-[8px] bg-white rounded-[20px] border-[0.5px] border-black-100 font-poppins-semibold"
            >
              Create Trade Group
            </button>
          </span>
          {selectedOptionDetail.trade_group &&
          selectedOptionDetail.trade_group.length ? (
            <div className="flex flex-col gap-[8px]">
              {selectedOptionDetail.trade_group
                .filter((g) => !g.is_deleted)
                .map((group) => (
                  <span
                    key={group._id}
                    className="text-sm flex flex-row justify-between items-center w-full"
                  >
                    <p className="font-poppins-semibold w-[35%]">
                      {group.name}
                    </p>
                    <p className="w-[45%]">
                      CKU {thousandSeparator(group.min_trade)} - CKU{' '}
                      {thousandSeparator(group.max_trade)}
                    </p>
                    <button
                      type="button"
                      className="flex"
                      onClick={() => {
                        dispatch(setSelectedTradeGroup(group));
                        setShowGroupModal(true);
                      }}
                    >
                      <ion-icon
                        name="create-outline"
                        style={{ width: '16px', height: '16px' }}
                      ></ion-icon>
                    </button>
                  </span>
                ))}
            </div>
          ) : null}
        </div>
      </>
    );
  }

  return null;
};

export default TradeGroupForm;
