import moment from 'moment/moment';
import { useEffect, useRef, useState } from 'react';
import ImageUpload from '../Common/ImageUpload';
import { moveItem, processObjects } from '../../utils/FAQ';
import { useDispatch, useSelector } from 'react-redux';
import { createUpdateContent } from '../../actions/content';
import { setContentList, setFAQBanner } from '../../slices/contentSlice';
import Loading from '../Common/Loading';
import { enqueueSnackbar } from 'notistack';
import getToastMessage from '../../utils/toastMessage';

const FAQ = () => {
  const FAQList = useSelector((state) => state.content.contentList);
  const FAQBanner = useSelector((state) => state.content.FAQBanner);
  const isLoading = useSelector((state) => state.content.isLoading);
  const [previewBanner, setPreviewBanner] = useState('');
  const listEl = useRef();
  const FAQ = {
    content_type: 'FAQ',
    title: '',
    content: '',
  };
  const dispatch = useDispatch();

  const addNewFAQ = () => {
    let newArr = [...FAQList, FAQ];
    dispatch(setContentList(newArr));
    if (listEl && listEl.current) {
      listEl.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const updateField = (index) => (e) => {
    let newArr = [...FAQList],
      name = e.target.name,
      newObj = { ...newArr[index] };

    newObj[name] = e.target.value;
    newArr[index] = newObj;

    dispatch(setContentList(newArr));
  };

  const moveFAQOrder = (idx, type) => {
    let newOrderFAQ = moveItem(FAQList, idx, type);
    if (newOrderFAQ && newOrderFAQ.length) {
      dispatch(setContentList(newOrderFAQ));
    }
  };

  const onRemoveFAQ = (idx) => {
    let parsedFAQList = JSON.parse(JSON.stringify(FAQList));
    parsedFAQList[idx].is_deleted = true;
    if (!parsedFAQList[idx].title && !parsedFAQList[idx].content) {
      parsedFAQList = parsedFAQList
        .slice(0, idx)
        .concat(parsedFAQList.slice(idx + 1));
    }
    console.log(parsedFAQList, '====newarr');
    dispatch(setContentList(parsedFAQList));
  };

  const showToast = (message, variant = 'warning') => {
    enqueueSnackbar(message ? message : 'Contents are posted and published', {
      variant,
    });
  };

  const saveFAQ = async () => {
    if (!FAQBanner) return showToast('Please provide FAQ Banner');

    var updatedList = processObjects(FAQList);
    if (!updatedList) return showToast('Please complete the FAQ form');

    updatedList = updatedList.map((faq) => {
      faq['banner'] = FAQBanner;
      return faq;
    });
    const done = await dispatch(createUpdateContent(updatedList));
    if (done) {
      const { message, variant } = getToastMessage(done);
      if (variant === 'success') showToast(message, variant);
    }
  };

  useEffect(() => {
    if (previewBanner) {
      dispatch(setFAQBanner(previewBanner));
    }
  }, [dispatch, previewBanner]);

  return (
    <>
      {isLoading ? <Loading /> : null}
      <div className="flex flex-col">
        <ImageUpload type="faq" name="faq-banner" setImage={setPreviewBanner} />
        <div className="flex flex-row justify-between items-center">
          <h3 className="font-poppins-semibold text-base">FAQ</h3>
          <button
            type="button"
            onClick={addNewFAQ}
            className="font-poppins-semibold px-[16px] py-[8px] border-[0.5px] border-black-100 rounded-[20px] bg-white"
          >
            Add a New FAQ Topic
          </button>
        </div>
        <div
          className="flex flex-col w-full lg:flex-row lg:gap-[16px] my-[16px]"
          onClick={() => {
            let imgBtn = document.getElementById('image-upload');
            imgBtn.click();
          }}
        >
          <div
            className={`flex justify-center items-center h-[162px] w-full lg:w-[75%] ${
              FAQBanner ? 'bg-transparent' : 'bg-black-100'
            }`}
          >
            {FAQBanner ? (
              <img
                src={FAQBanner}
                alt="faq banner"
                className="w-full h-full object-cover"
              />
            ) : (
              <button
                type="button"
                className="w-[80%] lg:w-[60%] font-poppins-semibold px-[16px] py-[8px] border-[0.5px] border-black-100 rounded-[20px] bg-white"
              >
                Upload Image
              </button>
            )}
          </div>
          {FAQList.length ? (
            <p className="font-poppins-semibold lg:w-[20%] lg:text-sm mt-[16px] lg:mt-0">
              Last update at:{' '}
              <span className="font-poppins">
                {moment(FAQList[0].updatedAt).format('DD-MM-YYYY, HH:mm')}
              </span>
            </p>
          ) : null}
        </div>
        {FAQList.length ? (
          <div>
            {FAQList.map((faq, idx) => (
              <div key={idx}>
                {faq.is_deleted ? null : (
                  <div className="flex flex-row text-sm mt-[16px]">
                    <div className="flex flex-col items-center">
                      <button
                        className="flex"
                        onClick={() => moveFAQOrder(idx, 'up')}
                      >
                        <ion-icon
                          name="caret-up-outline"
                          style={{
                            width: '20px',
                            height: '20px',
                            opacity: idx === 0 ? '0.3' : '1',
                          }}
                        ></ion-icon>
                      </button>
                      <p className="my-[5px]">{idx + 1}</p>
                      <button
                        className="flex"
                        onClick={() => moveFAQOrder(idx, 'down')}
                      >
                        <ion-icon
                          name="caret-down-outline"
                          style={{
                            width: '20px',
                            height: '20px',
                            opacity: idx === FAQList.length - 1 ? '0.3' : '1',
                          }}
                        ></ion-icon>
                      </button>
                    </div>
                    <div className="flex flex-col justify-between lg:flex-row mx-[16px] w-[80%] lg:w-[90%]">
                      <div className="flex flex-col mb-[16px] lg:w-[35%]">
                        <label>Topic title</label>
                        <input
                          className="text-sm bg-white py-[11px] px-[8px] border border-black-100 rounded-[6px] outline-0"
                          name="title"
                          value={faq.title}
                          onChange={updateField(idx)}
                        />
                      </div>
                      <div className="flex flex-col lg:w-[62%]">
                        <label>Explanation/Description/Content</label>
                        <textarea
                          className="text-sm bg-white p-[8px] border border-black-100 rounded-[6px] outline-0 min-h-[100px]"
                          name="content"
                          value={faq.content}
                          onChange={updateField(idx)}
                        />
                      </div>
                    </div>
                    <button className="flex" onClick={() => onRemoveFAQ(idx)}>
                      <ion-icon
                        name="trash-outline"
                        style={{ width: '20px', height: '20px' }}
                      ></ion-icon>
                    </button>
                  </div>
                )}
              </div>
            ))}
            <div
              className="w-full flex justify-center items-center mt-[32px]"
              ref={listEl}
            >
              <button
                type="button"
                onClick={saveFAQ}
                className="text-center bg-black-100 font-poppins-semibold text-white py-[10px] rounded-[20px] w-[70%] lg:w-[40%]  "
              >
                Save
              </button>
            </div>
          </div>
        ) : (
          <p>No FAQ data found</p>
        )}
      </div>
    </>
  );
};

export default FAQ;
