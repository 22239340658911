import { createAsyncThunk } from '@reduxjs/toolkit';
import { serverAPI } from '../utils/API';
import { enqueueSnackbar } from 'notistack';

export const fetchSeasonList = createAsyncThunk(
  'season/seasonList',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.get('adminLotterySeasonList', {
        params,
      });
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const fetchSeasonInfo = createAsyncThunk(
  'season/seasonInfo',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.get('adminLotterySeasonDetail', {
        params,
      });
      return data.lottery_season;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const fetchDrawdownWinnerList = createAsyncThunk(
  'season/drawdownWinnerList',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.get('adminDrawdownWinnerList', {
        params,
      });
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        if (error.response.data.message !== 'Drawdown not found') {
          enqueueSnackbar(error.response.data.message, { variant: 'error' });
        }
        return rejectWithValue(error.response.data.message);
      } else {
        if (error.message !== 'Drawdown not found') {
          enqueueSnackbar(error.message, { variant: 'error' });
        }
        return rejectWithValue(error.message);
      }
    }
  }
);

export const createSeason = createAsyncThunk(
  'season/createSeason',
  async (seasonData, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.post('createLotterySeason', seasonData);
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const editSeasonBasicInfo = createAsyncThunk(
  'season/editSeasonBasicInfo',
  async (seasonData, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.put(
        'editBasicLotterySeasonInfo',
        seasonData
      );
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const editSeasonStatus = createAsyncThunk(
  'season/editSeasonStatus',
  async (seasonData, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.put(
        'editLotterySeasonStatus',
        seasonData
      );
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const editSeasonBanner = createAsyncThunk(
  'season/editSeasonBanner',
  async (seasonData, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.put(
        'editLotterySeasonBanner',
        seasonData
      );
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const editSeasonSalesPitch = createAsyncThunk(
  'season/editSeasonSalesPitch',
  async (seasonData, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.put(
        'createUpdateSalesPitch',
        seasonData
      );
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteSeason = createAsyncThunk(
  'season/deleteSeason',
  async (seasonID, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.delete('deleteLotterySeason', {
        data: seasonID,
      });
      data.id = seasonID;
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);
