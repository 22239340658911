const Input = ({
  title,
  placeholder,
  type = 'text',
  register,
  name,
  width = '100%',
  errors,
  errorMessage,
  isDisabled = false,
  classes,
  defaultValue,
}) => {
  return (
    <div className={`flex flex-col mb-[18px] w-[${width}] ${classes}`}>
      <label className="text-sm">{title}</label>
      {name === 'phone_number' ? (
        <input
          type={type}
          placeholder={placeholder}
          {...register(name, { setValueAs: (value) => `${value}` })}
          className="text-sm py-[11px] px-[8px] border border-black-100 rounded-[6px] outline-0 bg-white"
        />
      ) : name === 'cku_amount' ? (
        <input
          type="text"
          placeholder={placeholder}
          {...register(name, {
            setValueAs: (value) =>
              value && typeof value === 'string'
                ? +value.replace(/[^0-9.]/g, '')
                : value,
          })}
          className="text-sm py-[11px] px-[8px] border border-black-100 rounded-[6px] outline-0 bg-white"
        />
      ) : (
        <input
          type={type}
          step="any"
          disabled={isDisabled}
          placeholder={placeholder}
          defaultValue={defaultValue}
          {...register(name)}
          className={`text-sm py-[11px] px-[8px] border border-black-100 rounded-[6px] outline-0 bg-white ${
            isDisabled ? 'opacity-30 cursor-not-allowed' : 'opacity-100'
          }`}
        />
      )}

      {(errors && errors[`${name}`]) || errorMessage ? (
        <p className="text-xs text-red">
          {errorMessage ? errorMessage : errors[`${name}`].message}
        </p>
      ) : null}
    </div>
  );
};

export default Input;
