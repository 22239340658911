import { enqueueSnackbar } from 'notistack';

const WinningNumbers = ({
  numbers,
  extra = null,
  showCopy = true,
  noPadding = false,
}) => {
  return (
    <>
      {numbers && numbers.length ? (
        <div
          className={`flex flex-row items-center ${
            noPadding ? 'px-0' : 'px-[8px]'
          }`}
        >
          {numbers.map((num, idx) => (
            <span
              key={`${num}-${idx}`}
              className={`flex flex-col justify-center items-center w-[24px] h-[24px] mr-[4px] rounded-full bg-black-100`}
            >
              <p className="text-sm text-center font-poppins-semibold text-white">
                {num}
              </p>
            </span>
          ))}
          {extra !== null ? (
            <span className="flex flex-col justify-center items-center w-[24px] h-[24px] rounded-full bg-red">
              <p className="text-sm text-center font-poppins-semibold text-white">
                {extra}
              </p>
            </span>
          ) : null}
          {showCopy ? (
            <button
              className="flex ml-[12px]"
              onClick={() => {
                navigator.clipboard.writeText(
                  `${numbers.join(', ')}, ${extra}`
                );
                enqueueSnackbar('Sucessfully copied winning number', {
                  variant: 'success',
                });
              }}
            >
              <ion-icon
                name="copy-outline"
                style={{ width: '18px', height: '18px' }}
              ></ion-icon>
            </button>
          ) : null}
        </div>
      ) : null}
    </>
  );
};

export default WinningNumbers;
