import Modal from '../Modal/Modal';

const AdminBalanceModal = ({ closeModal }) => {
  return (
    <Modal title="Admin's Balance" closeModal={closeModal}>
      <ul className="list-decimal text-sm ml-[16px]">
        <li>
          Comes from 40% of the CKU raised from the lottery sales (total
          transaction) that set aside.
        </li>
        <li>
          Comes from 60% of the CKU raised from the lottery sales (total
          transaction) that set aside.
        </li>
      </ul>
    </Modal>
  );
};

export default AdminBalanceModal;
