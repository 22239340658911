import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import LayoutAdmin from '../components/Layout/LayoutAdmin';
import { fetchCoinList, fetchOptionDetail } from '../actions/binary';
import BinaryForm from '../components/Binary/BinaryForm';

const BinaryDetailPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (id && id !== 'new') {
      dispatch(fetchOptionDetail({ id: id }));
    }
    dispatch(fetchCoinList());
  }, [id, dispatch]);

  return (
    <LayoutAdmin
      pageTitle="Trading Game"
      pageSubtitle="List of Traded Currency, Add/Edit Currency"
    >
      <BinaryForm />
    </LayoutAdmin>
  );
};

export default BinaryDetailPage;
