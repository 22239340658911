import { useForm } from 'react-hook-form';
import Modal from '../Modal/Modal';
import { yupResolver } from '@hookform/resolvers/yup';
import Input from '../Input/Input';
import { useDispatch } from 'react-redux';
import { editPlayer } from '../../actions/players';
import getToastMessage from '../../utils/toastMessage';
import { enqueueSnackbar } from 'notistack';
import { useEffect } from 'react';
import { editPlayerForm } from '../../utils/validationForm';

const EditPlayerModal = ({ closeModal, playerDetail }) => {
  const { register, handleSubmit, reset, formState } = useForm({
    resolver: yupResolver(editPlayerForm),
  });
  const dispatch = useDispatch();

  const onSubmitForm = async (data) => {
    const done = await dispatch(editPlayer(data));
    const { message, variant } = getToastMessage(done);
    if (variant === 'success') {
      enqueueSnackbar(message, { variant });
      closeModal(false);
    }
  };

  useEffect(() => {
    if (playerDetail && playerDetail._id) {
      const payload = JSON.parse(JSON.stringify(playerDetail));
      payload.trader_id = playerDetail.player_id;
      reset(payload);
    }
  }, [reset, playerDetail]);

  return (
    <Modal title="Edit Trader" closeModal={closeModal}>
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <Input
          name="trader_id"
          placeholder="Trader ID"
          title="Trader ID"
          register={register}
          isDisabled={true}
          errors={formState.errors}
        />
        <div className="flex flex-col text-sm mb-[18px]">
          <label htmlFor="role">Trader Status</label>
          <div className="relative">
            <select
              name="status"
              defaultValue={playerDetail.status}
              {...register('status')}
              className="appearance-none bg-white py-[11px] px-[8px] border border-black-100 rounded-[6px] w-full outline-none"
            >
              <option value="" disabled hidden></option>
              <option value="Active">Active</option>
              <option value="Banned">Banned</option>
            </select>
            <span className="absolute right-[8px] top-[11px]">
              <ion-icon name="chevron-down-outline"></ion-icon>
            </span>
          </div>
        </div>
        {/* <Input
          name="phone_number"
          placeholder="Number"
          title="Phone Number"
          register={register}
          type="number"
          errors={formState.errors}
        /> */}
        <Input
          name="email"
          placeholder="input@mail.com"
          title="Email"
          register={register}
          errors={formState.errors}
        />
        <div className="flex flex-row justify-between items-center mt-[24px]">
          <button
            onClick={() => closeModal(false)}
            type="button"
            className="w-[45%] px-[16px] py-[8px] text-red border rounded-[20px] font-poppins-semibold"
          >
            Discard All
          </button>
          <button
            type="submit"
            className="w-[45%] px-[16px] py-[8px] bg-black-100 text-white border rounded-[20px] font-poppins-semibold"
          >
            Save
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default EditPlayerModal;
