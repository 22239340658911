import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { setSelectedMenu, setIsMobileNavOpen } from '../../slices/navbarSlice';
import Logo from '../../assets/imgs/duotrx-logo.png';
import NavIcon from './NavIcon';
import { Modal as ModalMUI } from '@mui/material';
import { setUserInfo } from '../../slices/authSlice';
import { getPageTitle } from '../../utils/pageTitle';

const Navbar = () => {
  const menus = useSelector((state) => state.navbar.availableMenus);
  const selectedMenu = useSelector((state) => state.navbar.selectedMenu);
  const isMobileNavOpen = useSelector((state) => state.navbar.isMobileNavOpen);
  const userInfo = useSelector((state) => state.auth.userInfo);
  const [openPlayersSub, setOpenPlayersSub] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const changeMenu = (menu) => {
    if (menu.name === 'Traders' && selectedMenu === 'Traders') {
      setOpenPlayersSub(!openPlayersSub);
    } else {
      updateMobileNav(false);
    }
    dispatch(setSelectedMenu(menu.name));
    setTimeout(() => {
      navigate(`${menu.path}`);
    }, 200);
  };

  const onLogout = () => {
    localStorage.removeItem('USER_KEY');
    updateMobileNav(false);
    dispatch(setUserInfo(null));
    navigate('/login');
  };

  const updateMobileNav = (val) => {
    dispatch(setIsMobileNavOpen(val));
  };

  useEffect(() => {
    const pageTitle = getPageTitle(location.pathname);
    dispatch(setSelectedMenu(pageTitle));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, location.pathname]);

  const selectedStyles = ['bg-blue', 'text-white', 'font-poppins-semibold'];

  return (
    <div className="sm:w-[15%] sm:h-full sm:fixed z-10">
      <ModalMUI
        open={isMobileNavOpen ? true : false}
        onClose={() => updateMobileNav(!isMobileNavOpen)}
        className="sm:hidden"
      >
        <div
          id="mobile-nav"
          className="sm:block h-full overflow-hidden w-[60%] sm:w-full sm:static bg-[#fff] shadow-navbar absolute top-0 right-0 py-0 px-[16px]"
        >
          <div className="mb-[20px] h-[69px] w-full mt-[24px]">
            <img
              src={Logo}
              className="w-full h-full object-contain ml-[-10px]"
              alt="lucky cat logo"
            />
          </div>
          {menus.map((menu, index) => (
            <div key={index}>
              {menu.name === 'Recruiters' && !openPlayersSub ? null : (
                <div
                  className={`flex flex-row py-[10px] px-[16px] mb-[8px] rounded-lg cursor-pointer ${
                    selectedMenu === menu.name && selectedStyles.join(' ')
                  }`}
                  onClick={() => changeMenu(menu)}
                >
                  <NavIcon
                    type={menu.name.toLowerCase()}
                    active={selectedMenu === menu.name}
                  />
                  {menu.name === 'Traders' ? (
                    <div className="flex flex-col ml-[12px]">
                      <div className="flex flex-row items-center">
                        <p
                          className={`mr-[16px] ${
                            selectedMenu === 'Recruiters' &&
                            'font-poppins-semibold'
                          }`}
                        >
                          {menu.name}
                        </p>
                        <ion-icon
                          name={
                            openPlayersSub
                              ? 'chevron-up-outline'
                              : 'chevron-down-outline'
                          }
                          className="w-[18px] h-[18px]"
                        ></ion-icon>
                      </div>
                    </div>
                  ) : (
                    <p className={`ml-[12px]`}>{menu.name}</p>
                  )}
                </div>
              )}
            </div>
          ))}
          {userInfo ? (
            <div className="flex flex-row justify-between w-full items-center bg-white p-[20px] absolute bottom-[20px] left-0">
              <div className="flex flex-col ml-[8px]">
                <p>{userInfo.name}</p>
                <p className="text-sm opacity-50">{userInfo.role}</p>
              </div>
              <button className="bg-transparent flex" onClick={onLogout}>
                <ion-icon
                  name="log-out-outline"
                  style={{ width: '20px', height: '20px' }}
                ></ion-icon>
              </button>
            </div>
          ) : null}
        </div>
      </ModalMUI>

      <div className="hidden sm:block h-full overflow-hidden w-[60%] sm:w-full sm:static bg-[#fff] shadow-navbar absolute top-0 right-0 py-0 px-[16px]">
        <div className="mb-[20px] h-[69px] w-full mt-[24px]">
          <img
            src={Logo}
            className="w-full h-full object-contain ml-[-10px]"
            alt="lucky cat logo"
          />
        </div>
        {menus.map((menu, index) => (
          <div key={index}>
            {menu.name === 'Recruiters' && !openPlayersSub ? null : (
              <div
                className={`flex flex-row py-[10px] px-[16px] mb-[8px] rounded-lg cursor-pointer ${
                  selectedMenu === menu.name && selectedStyles.join(' ')
                }`}
                onClick={() => changeMenu(menu)}
              >
                <NavIcon
                  type={menu.name.toLowerCase()}
                  active={selectedMenu === menu.name}
                />
                {menu.name === 'Traders' ? (
                  <div className="flex flex-col ml-[12px]">
                    <div className="flex flex-row items-center">
                      <p
                        className={`mr-[16px] ${
                          selectedMenu === 'Recruiters' &&
                          'font-poppins-semibold'
                        }`}
                      >
                        {menu.name}
                      </p>
                      <ion-icon
                        name={
                          openPlayersSub
                            ? 'chevron-up-outline'
                            : 'chevron-down-outline'
                        }
                        className="w-[18px] h-[18px]"
                      ></ion-icon>
                    </div>
                  </div>
                ) : (
                  <p className="ml-[12px]">{menu.name}</p>
                )}
              </div>
            )}
          </div>
        ))}
        {userInfo ? (
          <div className="flex flex-row w-full justify-between items-center bg-white p-[20px] absolute bottom-[20px] left-0">
            <div className="flex flex-col ml-[8px]">
              <p>{userInfo.name}</p>
              <p className="text-sm opacity-50">{userInfo.role}</p>
            </div>
            <button className="bg-transparent flex" onClick={onLogout}>
              <ion-icon
                name="log-out-outline"
                style={{ width: '20px', height: '20px' }}
              ></ion-icon>
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Navbar;
