import { useDispatch, useSelector } from 'react-redux';
import Loading from '../Common/Loading';
import { useEffect, useRef, useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import getToastMessage from '../../utils/toastMessage';
import Input from '../Input/Input';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { optionForm } from '../../utils/validationForm';
import { convertImgFileToLink } from '../../utils/convToBase64';
import { setIsLoading } from '../../slices/binarySlice';
import { useNavigate, useParams } from 'react-router-dom';
import { createOption, editOption } from '../../actions/binary';
import { useDebounce } from '../../hooks/useDebounce';
import { resetOptionDetail } from '../../slices/binarySlice';
import ActionButton from '../Button/ActionButton';
import DeleteCoin from './DeleteCoinModal';
import TradeGroupForm from './TradeGroupForm';
import CoinActivationStatus from './CoinActivationStatus';
import PopupBannerForm from './PopupBannerForm';

const BinaryForm = () => {
  const [showDeleteCoin, setShowDeleteCoin] = useState(false);
  const { selectedOptionDetail, isLoading, coinList, availableInterval } =
    useSelector((state) => state.binary);
  const [isCoinInputFocus, setIsCoinInputFocus] = useState(false);
  const [filteredCoinList, setFilteredCoinList] = useState([]);
  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    watch,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(optionForm),
  });
  const dispatch = useDispatch();
  const uploadImgRef = useRef();
  const submitFormBtn = useRef();
  const { id } = useParams();
  const navigate = useNavigate();
  const watchIntervalUnit = watch('time_interval.unit');

  const onSubmit = async (data) => {
    if (data.coin_info.currency) {
      const validCoin = coinList.filter(
        (coin) =>
          coin.name === data.coin_info.name &&
          coin.currency === data.coin_info.currency
      );
      if (!validCoin.length) {
        return enqueueSnackbar('Invalid coin! Please check again', {
          variant: 'warning',
        });
      }
    }

    data.banner = {
      status: data.use_banner,
      message: data.banner_message,
      image: data.banner_image,
      button_name: data.button_name,
      button_link: data.button_link,
    };

    if (id !== 'new') data.binary_option_id = id;

    const done = await dispatch(
      id && id === 'new' ? createOption(data) : editOption(data)
    );
    if (done) {
      const { message, variant } = getToastMessage(done);
      if (variant === 'success') {
        enqueueSnackbar(message, { variant });
        if (id === 'new') {
          navigate(`/option/${done.payload.details._id}`, { replace: true });
        }
      }
    }
  };

  const handleSearch = useDebounce((term) => {
    console.log('Searching for:', term);
    if (term) {
      term = term.toLowerCase();
      const filtered = coinList.filter(
        (coin) =>
          coin.name.toLowerCase().includes(term) ||
          coin.currency.toLowerCase().includes(term)
      );
      setFilteredCoinList(filtered);
    } else {
      setFilteredCoinList([]);
    }
  }, 350);

  const selectCoin = (coin) => {
    console.log(coin, '====selectedcoin');
    setValue('coin_info', coin);
    setFilteredCoinList([]);
  };

  useEffect(() => {
    if (id && id !== 'new' && selectedOptionDetail) {
      const { banner } = selectedOptionDetail;
      const detail = JSON.parse(JSON.stringify(selectedOptionDetail));
      if (banner) {
        detail.banner_image = banner.image;
        detail.use_banner = banner.status;
        detail.banner_message = banner.message;
        detail.button_name = banner.button_name;
        detail.button_link = banner.button_link;
      }

      delete detail.banner;
      delete detail.trade_group;
      delete detail.createdAt;
      delete detail.created_by;
      delete detail.updatedAt;
      delete detail.updated_by;
      delete detail.is_deleted;

      reset(detail);
    }
  }, [dispatch, id, reset, selectedOptionDetail]);

  useEffect(() => {
    return () => {
      dispatch(resetOptionDetail());
    };
  }, [dispatch]);

  return (
    <>
      {isLoading ? <Loading /> : null}
      {showDeleteCoin ? <DeleteCoin closeModal={setShowDeleteCoin} /> : null}
      <div className="flex flex-col lg:flex-row lg:justify-between">
        <div className="lg:w-[65%]">
          <h3 className="text-green font-poppins-semibold text-base mb-[16px]">
            Currency Setup
          </h3>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col sm:flex-row sm:justify-between"
          >
            <div className="lg:w-[48%]">
              <Controller
                control={control}
                name="icon"
                render={({ field: { value, onChange } }) => {
                  return (
                    <div className="flex flex-col mb-[18px]">
                      <input
                        ref={uploadImgRef}
                        type="file"
                        accept="image/*"
                        className="hidden"
                        onChange={(event) => {
                          const file = event.target.files[0];
                          if (file) {
                            dispatch(setIsLoading(true));
                            convertImgFileToLink(file, 'coin-icon', 'icon')
                              .then((res) => {
                                onChange(res);
                              })
                              .catch((error) => {
                                if (
                                  error.response &&
                                  error.response.data.message
                                ) {
                                  enqueueSnackbar(error.response.data.message, {
                                    variant: 'error',
                                  });
                                } else {
                                  enqueueSnackbar(error.message, {
                                    variant: 'error',
                                  });
                                }
                              })
                              .finally(() => dispatch(setIsLoading(false)));
                          }
                        }}
                      />
                      <label className="text-sm">Icon</label>
                      <div
                        className={`relative flex flex-col w-[92px] h-[92px] rounded-lg cursor-pointer ${
                          value ? 'bg-transparent' : 'bg-grey-banner'
                        }`}
                      >
                        {value ? (
                          <>
                            <button
                              type="button"
                              className="flex absolute top-[4px] right-[4px] bg-black-100 opacity-25 z-10"
                              onClick={() => onChange('')}
                            >
                              <ion-icon
                                name="close-outline"
                                style={{
                                  color: 'white',
                                  width: '12px',
                                  height: '12px',
                                }}
                              ></ion-icon>
                            </button>
                            <img
                              src={value}
                              alt="coin-icon"
                              onClick={() => {
                                uploadImgRef.current.click();
                              }}
                              className="w-full h-full object-contain"
                            />
                          </>
                        ) : (
                          <div
                            className="flex flex-col items-center justify-center w-full h-full"
                            onClick={() => {
                              uploadImgRef.current.click();
                            }}
                          >
                            <ion-icon
                              name="images-outline"
                              style={{ width: '16px', height: '16px' }}
                            ></ion-icon>
                            <p className="text-sm">Upload Icon</p>
                          </div>
                        )}
                      </div>
                      {errors && errors.icon && (
                        <p className="text-xs text-red">
                          {errors.icon.message}
                        </p>
                      )}
                    </div>
                  );
                }}
              />
              <div className="flex flex-col relative mb-[18px]">
                <label className="text-sm">Currency against USD</label>
                <input
                  type="text"
                  placeholder="Name"
                  onFocus={(ev) => {
                    setIsCoinInputFocus(true);
                    if (ev.target.value) handleSearch(ev.target.value);
                  }}
                  {...register('coin_info.name', {
                    onChange: (ev) => {
                      handleSearch(ev.target.value);
                    },
                    onBlur: () => {
                      setTimeout(() => {
                        setIsCoinInputFocus(false);
                      }, 250);
                    },
                  })}
                  className="text-sm py-[11px] px-[8px] border border-black-100 rounded-[6px] outline-0 bg-white"
                />
                {isCoinInputFocus ? (
                  <div className="max-h-[40vh] cursor-pointer overflow-auto absolute z-10 top-[60px] left-0 flex flex-col p-[12px] w-full gap-[5px] bg-[rgba(255,255,255,0.10)] backdrop-blur-md rounded-lg border border-[#DADADA]">
                    {filteredCoinList.length ? (
                      filteredCoinList.map((coin) => (
                        <span
                          key={coin.id}
                          className={`text-sm flex flex-row items-center justify-between ${
                            getValues('coin_info.currency') === coin.currency
                              ? 'font-poppins-bold text-green'
                              : ''
                          }`}
                          onClick={() => selectCoin(coin)}
                        >
                          {coin.name}
                        </span>
                      ))
                    ) : (
                      <p className="text-sm font-poppins-semibold">
                        Coin not found!
                      </p>
                    )}
                  </div>
                ) : null}
                {errors && errors.coin_info && errors.coin_info.name && (
                  <p className="text-xs text-red">
                    {errors.coin_info.name.message}
                  </p>
                )}
              </div>
              <div className="flex flex-row justify-between items-start">
                <div className="relative flex flex-col w-[48%] text-sm mb-[18px]">
                  <label>Time Interval</label>
                  <select
                    {...register('time_interval.value')}
                    className="appearance-none bg-white py-[11px] px-[8px] border border-black-100 rounded-[6px] outline-none"
                  >
                    {availableInterval.map((interval) => (
                      <option
                        value={
                          watchIntervalUnit === 'Min' ? interval / 60 : interval
                        }
                      >
                        {watchIntervalUnit === 'Min' ? interval / 60 : interval}
                      </option>
                    ))}
                  </select>
                  <span className="absolute right-[8px] top-[32px]">
                    <ion-icon name="chevron-down-outline"></ion-icon>
                  </span>
                </div>
                <div className="relative flex flex-col w-[48%] text-sm mb-[18px]">
                  <label>Time Type</label>
                  <select
                    {...register('time_interval.unit')}
                    className="appearance-none bg-white py-[11px] px-[8px] border border-black-100 rounded-[6px] outline-none"
                  >
                    <option value="Min">Min</option>
                    <option value="Sec">Sec</option>
                  </select>
                  <span className="absolute right-[8px] top-[32px]">
                    <ion-icon name="chevron-down-outline"></ion-icon>
                  </span>
                </div>
              </div>
              <div className="flex flex-row justify-between items-start">
                <Input
                  title="Latest Time to Join"
                  type="number"
                  name="latest_trade_time.value"
                  placeholder="Time"
                  errors={errors}
                  register={register}
                  classes="w-[48%]"
                />
                <div className="relative flex flex-col w-[48%] text-sm mb-[18px]">
                  <label>Time Type</label>
                  <select
                    {...register('latest_trade_time.unit')}
                    className="appearance-none bg-white py-[11px] px-[8px] border border-black-100 rounded-[6px] outline-none"
                  >
                    <option value="Min">Min</option>
                    <option value="Sec">Sec</option>
                  </select>
                  <span className="absolute right-[8px] top-[32px]">
                    <ion-icon name="chevron-down-outline"></ion-icon>
                  </span>
                </div>
              </div>
              <Input
                title="Losing Pot Percentage for Winners"
                type="number"
                name="losing_pot_percentage"
                placeholder="%"
                errors={errors}
                register={register}
              />
              <div className="flex flex-row justify-between items-start">
                <Input
                  title="Referral Percentage"
                  type="number"
                  name="referral_percentage"
                  placeholder="%"
                  errors={errors}
                  register={register}
                  classes="w-[48%]"
                />
                <Input
                  title="Associate Percentage"
                  name="associate_percentage"
                  placeholder="%"
                  errors={errors}
                  register={register}
                  classes="w-[48%]"
                />
              </div>
              <Input
                title="Executive Percentage"
                name="executive_percentage"
                placeholder="%"
                errors={errors}
                register={register}
              />
            </div>
            <div className="lg:w-[48%]">
              <CoinActivationStatus />
              <PopupBannerForm
                control={control}
                register={register}
                errors={errors}
              />
            </div>
            <button
              type="submit"
              onSubmit={handleSubmit(onSubmit)}
              className="hidden"
              ref={submitFormBtn}
            >
              Submit
            </button>
          </form>
        </div>
        <div className="flex flex-col lg:w-[32%]">
          <TradeGroupForm />
        </div>
      </div>
      <div className="flex flex-col sm:flex-row justify-center gap-[12px]">
        <button
          type="button"
          onClick={() => {
            submitFormBtn.current.click();
          }}
          className="w-full sm:!w-[35%] px-[16px] py-[8px] bg-black-100 text-white rounded-[20px] border-[0.5px] border-black-100 font-poppins-semibold"
        >
          Save
        </button>
        <ActionButton
          title="Delete Coin"
          classes="rounded-[20px] !bg-red !border-0 sm:!w-[35%] !h-fit !py-[8px] !text-base"
          onClick={() => setShowDeleteCoin(true)}
        />
      </div>
    </>
  );
};

export default BinaryForm;
