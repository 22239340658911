import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isMobileNavOpen: null,
  selectedMenu: 'Dashboard',
  availableMenus: [
    {
      name: 'Dashboard',
      path: '/',
    },
    {
      name: 'Binary Option',
      path: '/option',
    },
    {
      name: 'Transactions',
      path: '/transactions',
    },
    {
      name: 'Traders',
      path: '/traders',
    },
    {
      name: 'Recruiters',
      path: '/recruiters',
    },
    {
      name: 'Admins',
      path: '/admins',
    },
    {
      name: 'Contact',
      path: '/contact',
    },
    {
      name: 'Pages Content',
      path: '/content',
    },
  ],
};

export const navbar = createSlice({
  name: 'navbar',
  initialState,
  reducers: {
    setSelectedMenu: (state, action) => {
      state.selectedMenu = action.payload;
    },
    setIsMobileNavOpen: (state, action) => {
      state.isMobileNavOpen = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setSelectedMenu, setIsMobileNavOpen } = navbar.actions;

export default navbar.reducer;
