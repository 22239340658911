import { useDispatch } from 'react-redux';
import Modal from './Modal';
import { deleteContact } from '../../actions/contact';
import { enqueueSnackbar } from 'notistack';
import getToastMessage from '../../utils/toastMessage';

const ContactDeleteModal = ({ closeModal, contactID }) => {
  const dispatch = useDispatch();
  const onDeleteContact = async () => {
    const done = await dispatch(deleteContact({ _id: contactID }));
    if (done) {
      const { message, variant } = getToastMessage(done);
      if (variant === 'success') {
        enqueueSnackbar(message, { variant });
        closeModal(1);
      }
    }
  };

  return (
    <Modal title="Delete Contact" closeModal={closeModal}>
      <h3 className="text-base">
        Are you certain about deleting this contact?
      </h3>
      <div className="flex flex-row justify-between items-center mt-[24px]">
        <button
          onClick={() => closeModal(false)}
          type="button"
          className="w-[45%] px-[16px] py-[8px] text-red border rounded-[20px] font-poppins-semibold"
        >
          Cancel
        </button>
        <button
          type="button"
          onClick={onDeleteContact}
          className="w-[45%] px-[16px] py-[8px] bg-black-100 text-white border rounded-[20px] font-poppins-semibold"
        >
          Delete
        </button>
      </div>
    </Modal>
  );
};

export default ContactDeleteModal;
