import { createAsyncThunk } from '@reduxjs/toolkit';
import { serverAPI } from '../utils/API';
import { enqueueSnackbar } from 'notistack';

export const fetchTransactionSummary = createAsyncThunk(
  'dashboard/transactionHomeSummary',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.get('tradeHomeSummary', {
        params,
      });
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getAdminBalance = createAsyncThunk(
  'dashboard/getAdminBalance',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.get('getAdminBalance', {
        params,
      });
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getUSDPrice = createAsyncThunk(
  'dashboard/getUSDPrice',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.get('adminGetUSDPrice', {});
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);
