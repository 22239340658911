import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useIsSuperAdmin } from '../hooks/useAuth';
import { setNewAdmins, setSelectedAdminInfo } from '../slices/adminSlice';
import LayoutAdmin from '../components/Layout/LayoutAdmin';
import AdminNewModal from '../components/Modal/AdminNewModal';
import { fetchAdminInfo } from '../actions/admin';
import Loading from '../components/Common/Loading';
import moment from 'moment';

const AdminDetailPage = () => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [passType, setPassType] = useState('password');
  const { selectedAdminInfo, newAdmins, isLoading } = useSelector(
    (state) => state.admin
  );
  const { id } = useParams();
  const dispatch = useDispatch();
  const isSuperAdmin = useIsSuperAdmin();

  useEffect(() => {
    if (id) dispatch(fetchAdminInfo({ id }));

    return () => {
      if (isSuperAdmin && newAdmins && newAdmins.length) {
        const newAdminList = newAdmins.filter((admin) => admin._id !== id);
        // console.log(newAdminList, '===newadminlist after filter');
        dispatch(setNewAdmins(newAdminList));
      }
      dispatch(setSelectedAdminInfo(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);

  return (
    <>
      {isLoading ? <Loading /> : null}
      {openEditModal ? (
        <AdminNewModal isEdit={true} closeModal={setOpenEditModal} />
      ) : null}
      <LayoutAdmin
        pageTitle="Admin List"
        pageSubtitle="Admin List, Admin Detail"
      >
        <div className="flex flex-row justify-between items-center mb-[16px] lg:justify-start">
          <h3 className="font-poppins-semibold text-base">Admin Detail</h3>
          <button
            onClick={() => isSuperAdmin && setOpenEditModal(true)}
            className={`px-[16px] py-[8px] border rounded-[20px] font-poppins-semibold bg-white lg:ml-[20%] ${
              isSuperAdmin ? 'opacity-100' : 'opacity-50 cursor-not-allowed'
            }`}
          >
            Edit Admin
          </button>
        </div>
        {selectedAdminInfo ? (
          <div className="w-full">
            <div className="text-sm flex flex-row items-center">
              <p className="font-poppins-semibold w-[50%] lg:w-[25%]">Name</p>
              <span className="font-poppins">{selectedAdminInfo.role}</span>
            </div>
            <div className="text-sm flex flex-row items-center">
              <p className="font-poppins-semibold w-[50%] lg:w-[25%]">Phone</p>
              <span className="font-poppins">
                {selectedAdminInfo.phone_number}
              </span>
            </div>
            <div className="text-sm flex flex-row items-center">
              <p className="font-poppins-semibold w-[50%] lg:w-[25%]">Email</p>
              <span className="font-poppins">{selectedAdminInfo.email}</span>
            </div>
            <div className="text-sm flex flex-row items-center">
              <p className="font-poppins-semibold w-[50%] lg:w-[25%] ">Role</p>
              <span className="font-poppins">{selectedAdminInfo.role}</span>
            </div>
            {isSuperAdmin && selectedAdminInfo && selectedAdminInfo.password ? (
              <div className="text-sm flex flex-row items-center">
                <p className="font-poppins-semibold w-[50%] lg:w-[25%] ">
                  Temporary Password
                </p>
                <input
                  type={passType}
                  className="font-poppins"
                  value={selectedAdminInfo.password}
                  disabled
                />
                <button
                  className="flex"
                  onClick={() =>
                    setPassType(passType === 'text' ? 'password' : 'text')
                  }
                >
                  <ion-icon
                    name={
                      passType === 'password'
                        ? 'eye-outline'
                        : 'eye-off-outline'
                    }
                    style={{ width: '16px', height: '16px' }}
                  ></ion-icon>
                </button>
              </div>
            ) : null}
            <div className="text-sm flex flex-row items-center">
              <p className="font-poppins-semibold w-[50%] lg:w-[25%] ">
                Last Login
              </p>
              <span className="font-poppins">
                {selectedAdminInfo.last_login
                  ? moment(selectedAdminInfo.last_login).format(
                      'DD-MM-YYYY, HH:mm'
                    )
                  : 'No login data'}
              </span>
            </div>
          </div>
        ) : null}
      </LayoutAdmin>
    </>
  );
};

export default AdminDetailPage;
