import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'notistack';
import getToastMessage from '../../utils/toastMessage';
import Modal from '../Modal/Modal';
import { useNavigate, useParams } from 'react-router-dom';
import { deleteOption } from '../../actions/binary';

const DeleteCoin = ({ closeModal }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const onDeletePitch = async () => {
    const payload = { _id: id };

    const done = await dispatch(deleteOption(payload));
    if (done) {
      const { message, variant } = getToastMessage(done);
      if (variant === 'success') {
        enqueueSnackbar(message, { variant });
        navigate('/option');
        closeModal(false);
      }
    }
  };
  return (
    <Modal title="Delete Coin" closeModal={closeModal}>
      <h3 className="text-base">Are you certain about deleting this coin?</h3>
      <div className="flex flex-row justify-between items-center mt-[24px]">
        <button
          onClick={() => closeModal(false)}
          type="button"
          className="w-[45%] px-[16px] py-[8px] text-red border border-black-100 rounded-[20px] font-poppins-semibold"
        >
          Cancel
        </button>
        <button
          type="button"
          onClick={onDeletePitch}
          className="w-[45%] px-[16px] py-[8px] bg-black-100 text-white border rounded-[20px] font-poppins-semibold"
        >
          Delete
        </button>
      </div>
    </Modal>
  );
};

export default DeleteCoin;
