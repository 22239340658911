import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const SeasonSection = () => {
  const { sections, selectedSection } = useSelector(
    (state) => state.seasonSection
  );
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();

  const changeSection = (section) => {
    if (section !== selectedSection) {
      let url = `/lottery/season/${id}?season_section=${section}`;
      navigate(url);
    }
  };

  return (
    <>
      {location.pathname.includes('lottery') &&
      (location.pathname.includes('season') ||
        location.pathname.includes('reward') ||
        location.pathname.includes('winners')) ? (
        <div className="flex flex-row overflow-auto max-w-none w-full lg:ml-[12px]">
          {sections.map((section) => (
            <button
              key={section.section}
              onClick={() => changeSection(section.section)}
              className={`${
                selectedSection === section.section
                  ? 'opacity-100'
                  : 'opacity-20'
              } bg-green text-ivory border-r-[2px] py-[12px] px-[16px] font-poppins-semibold box-shadow-green min-w-max`}
            >
              {section.name}
            </button>
          ))}
        </div>
      ) : null}
    </>
  );
};

export default SeasonSection;
