import { createAsyncThunk } from '@reduxjs/toolkit';
import { enqueueSnackbar } from 'notistack';
import { serverAPI } from '../utils/API';
import { setAdminBalance } from '../slices/dashboardSlice';
import { setSelectedPlayerDetail } from '../slices/playersSlice';

export const checkTransactionId = createAsyncThunk(
  'wallet/checkTransactionId',
  async (transactionData, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await serverAPI.post('adminGetCku', transactionData);
      if (data && data.new_admin_balance_data) {
        dispatch(setAdminBalance(data.new_admin_balance_data));
      }
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const sendLuckyCoinInternal = createAsyncThunk(
  'wallet/sendLuckyCoinInternal',
  async (transactionData, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await serverAPI.post(
        'adminSendCkuInternal',
        transactionData
      );
      if (data && data.new_admin_balance_data) {
        dispatch(setAdminBalance(data.new_admin_balance_data));
      }
      if (data && data.new_player_data) {
        dispatch(setSelectedPlayerDetail(data.new_player_data));
      }
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const withdrawLuckyCoin = createAsyncThunk(
  'wallet/withdrawLuckyCoin',
  async (withdrawData, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await serverAPI.post('adminWithdrawCku', withdrawData);
      if (data && data.new_admin_balance_data) {
        dispatch(setAdminBalance(data.new_admin_balance_data));
      }
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const sendConnectWalletOTP = createAsyncThunk(
  'wallet/sendConnectWalletOTP',
  async (walletData, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.post(
        'adminSendConnectWalletOTP',
        walletData
      );
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const sendTransferOTP = createAsyncThunk(
  'wallet/sendTransferOTP',
  async (withdrawData, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.post(
        'adminSendTransferOTP',
        withdrawData
      );
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const sendWithdrawOTP = createAsyncThunk(
  'wallet/sendWithdrawOTP',
  async (withdrawData, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.post(
        'adminSendWithdrawOTP',
        withdrawData
      );
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const checkReceiverName = createAsyncThunk(
  'wallet/checkReceiverName',
  async (walletData, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.post(
        'adminCheckReceiverName',
        walletData
      );
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const connectNewWallet = createAsyncThunk(
  'wallet/connectNewWallet',
  async (walletData, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.put('adminConnectWallet', walletData);
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);
export const editWalletInfo = createAsyncThunk(
  'wallet/editWalletInfo',
  async (walletData, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.put('adminEditWallet', walletData);
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const fetchWalletList = createAsyncThunk(
  'wallet/fetchWalletList',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.get('adminInfo', { params });
      return data.admin_data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);
