import { useForm } from 'react-hook-form';
import Modal from '../Modal/Modal';
import { yupResolver } from '@hookform/resolvers/yup';
import Input from '../Input/Input';
import { useDispatch } from 'react-redux';
import getToastMessage from '../../utils/toastMessage';
import { enqueueSnackbar } from 'notistack';
import { editWallet } from '../../utils/validationForm';
import { editWalletInfo } from '../../actions/wallet';
import { useEffect } from 'react';

const EditWallet = ({ closeModal, walletDetail }) => {
  const { register, handleSubmit, formState, reset } = useForm({
    resolver: yupResolver(editWallet),
  });
  const dispatch = useDispatch();

  const onSubmitForm = async (data) => {
    const done = await dispatch(editWalletInfo(data));
    const { message, variant } = getToastMessage(done);
    if (variant === 'success') {
      enqueueSnackbar(message, { variant });
      closeModal(false);
    }
  };

  useEffect(() => {
    if (walletDetail && walletDetail._id) {
      console.log(walletDetail, '====detail');
      reset(walletDetail);
    }
  }, [reset, walletDetail]);

  return (
    <Modal title="Edit Wallet" closeModal={closeModal}>
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <Input
          name="wallet_name"
          placeholder="Wallet Name"
          title="Wallet Name"
          register={register}
          errors={formState.errors}
        />
        <Input
          name="wallet_address"
          type="email"
          placeholder="input@mail.com"
          title="Wallet Address"
          register={register}
          isDisabled={true}
          errors={formState.errors}
        />
        <div className="flex flex-row justify-between items-center mt-[24px]">
          <button
            type="button"
            onClick={() => closeModal(false)}
            className="w-[45%] px-[16px] py-[8px] text-red border rounded-[20px] font-poppins-semibold"
          >
            Discard All
          </button>
          <button
            type="submit"
            className="w-[45%] px-[16px] py-[8px] bg-black-100 text-white border rounded-[20px] font-poppins-semibold"
          >
            Save
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default EditWallet;
