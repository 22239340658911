export function moveItem(array, index, type) {
  array = [...array];

  if (index < 0 || index >= array.length) return null;

  if (type === 'up' && index > 0) {
    const temp = array[index];
    array[index] = array[index - 1];
    array[index - 1] = temp;
  } else if (type === 'down' && index < array.length - 1) {
    const temp = array[index];
    array[index] = array[index + 1];
    array[index + 1] = temp;
  }

  return array;
}

export function processObjects(arr) {
  for (let i = 0; i < arr.length; i++) {
    const obj = arr[i];
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && obj[key] === '') {
        return false;
      }
    }
  }

  let newArr = [];
  for (let j = 0; j < arr.length; j++) {
    let obj = JSON.parse(JSON.stringify(arr[j]));
    newArr.push(obj);
  }

  newArr = newArr.map((array, idx) => {
    array['sortOrder'] = idx;
    if (!array._id) array._id = '';
    return array;
  });

  return newArr;
}
