import { createSlice } from '@reduxjs/toolkit';
import {
  createContact,
  deleteContact,
  editContact,
  fetchContactList,
} from '../actions/contact';

const initialState = {
  contactList: null,
  isLoading: false,
};

export const contact = createSlice({
  name: 'contact',
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
  extraReducers: {
    [fetchContactList.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchContactList.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.contactList = payload.contact_platform_list;
    },
    [fetchContactList.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [createContact.pending]: (state) => {
      state.isLoading = true;
    },
    [createContact.rejected]: (state) => {
      state.isLoading = false;
    },
    [createContact.fulfilled]: (state, { payload }) => {
      const { details } = payload;
      state.isLoading = false;
      state.contactList = [details, ...state.contactList];
    },
    [editContact.pending]: (state) => {
      state.isLoading = true;
    },
    [editContact.rejected]: (state) => {
      state.isLoading = false;
    },
    [editContact.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      const { details } = payload;
      const index = state.contactList
        .map((contact) => contact._id)
        .indexOf(details._id);
      state.contactList[index] = details;
    },
    [deleteContact.pending]: (state) => {
      state.isLoading = true;
    },
    [deleteContact.rejected]: (state) => {
      state.isLoading = false;
    },
    [deleteContact.fulfilled]: (state, { payload }) => {
      console.log(payload, '===payload delete contatc');
      state.contactList = state.contactList.filter(
        (contact) => contact._id !== payload.id
      );
      state.isLoading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setIsLoading } = contact.actions;

export default contact.reducer;
