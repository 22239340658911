import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';
import { newAdmin, updateAdmin } from '../../utils/validationForm';
import Input from '../Input/Input';
import Modal from './Modal';
import { editAdmin, registerAdmin } from '../../actions/admin';
import AdminDeleteModal from './AdminDeleteModal';
import { useNavigate } from 'react-router-dom';
import getToastMessage from '../../utils/toastMessage';

const AdminNewModal = ({ isEdit = false, closeModal }) => {
  const { register, handleSubmit, reset, watch, formState, setValue } = useForm(
    {
      resolver: yupResolver(isEdit ? updateAdmin : newAdmin),
    }
  );
  const selectedAdminInfo = useSelector(
    (state) => state.admin.selectedAdminInfo
  );
  const [resetAdminPass, setResetAdminPass] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  var watchRole = watch(
    'role',
    selectedAdminInfo && isEdit ? selectedAdminInfo.role : ''
  );

  useEffect(() => {
    if (isEdit && selectedAdminInfo) {
      const data = JSON.parse(JSON.stringify(selectedAdminInfo));
      data.password = '';
      reset(data);
    }
  }, [isEdit, reset, selectedAdminInfo, setValue]);

  useEffect(() => {
    if (showDeleteModal === 1) {
      closeModal(false);
      setTimeout(() => {
        navigate('/admins');
      }, 200);
    }
  }, [closeModal, navigate, showDeleteModal]);

  const onSubmit = async (data) => {
    // if (formState.isValid) {
    const registered = isEdit
      ? await dispatch(editAdmin(data))
      : await dispatch(registerAdmin(data));

    if (registered) {
      const { message, variant } = getToastMessage(registered);
      if (variant === 'success') {
        enqueueSnackbar(message, { variant });
        reset();
        setTimeout(() => {
          closeModal(false);
        }, 200);
      }
    }
    // }
  };

  const discardOrDelete = async () => {
    if (isEdit) {
      setShowDeleteModal(true);
    } else {
      reset();
    }
  };

  return (
    <>
      {isEdit && showDeleteModal ? (
        <AdminDeleteModal
          adminID={selectedAdminInfo._id}
          closeModal={setShowDeleteModal}
        />
      ) : null}
      <Modal
        title={isEdit ? 'Edit Admin' : 'Add Admin'}
        closeModal={closeModal}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            name="name"
            placeholder="Input name"
            title="Name"
            register={register}
            errors={formState.errors}
          />
          <Input
            name="phone_number"
            placeholder="Number"
            title="Phone Number"
            register={register}
            type="number"
            errors={formState.errors}
          />
          <Input
            name="email"
            placeholder="input@mail.com"
            title="Email"
            register={register}
            errors={formState.errors}
          />
          <div className="flex flex-col text-sm mb-[18px]">
            <label htmlFor="role">Role</label>
            <div className="relative">
              <select
                name="role"
                defaultValue=""
                {...register('role')}
                className="appearance-none bg-white py-[11px] px-[8px] border border-black-100 rounded-[6px] w-full outline-none"
              >
                <option value="" disabled hidden></option>
                <option value="Super Admin">Super Admin</option>
                <option value="Admin">Admin</option>
              </select>
              <span className="absolute right-[8px] top-[11px]">
                <ion-icon name="chevron-down-outline"></ion-icon>
              </span>
              {!watchRole ? (
                <p className="text-black-100 opacity-30 absolute left-[12px] top-[12px] text-sm">
                  Role
                </p>
              ) : null}
            </div>
          </div>
          {isEdit ? (
            <div
              className={`flex flex-row items-center mt-[25px] ${
                resetAdminPass && 'mb-[10px]'
              }`}
              onClick={() => setResetAdminPass(!resetAdminPass)}
            >
              <ion-icon
                name={resetAdminPass ? 'checkbox' : 'square-outline'}
                style={{ width: '20px', height: '20px' }}
              ></ion-icon>
              <label className="ml-[5px] text-sm">Reset Admin's Password</label>
            </div>
          ) : null}
          {!isEdit || (isEdit && resetAdminPass) ? (
            <Input
              name="password"
              placeholder="Temporary Password"
              title="Temporary Password"
              register={register}
            />
          ) : null}
          <div className="flex flex-row justify-between items-center mt-[24px]">
            <button
              type="button"
              onClick={discardOrDelete}
              className="w-[45%] px-[16px] py-[8px] text-red border rounded-[20px] font-poppins-semibold"
            >
              {isEdit ? 'Delete Admin' : 'Discard All'}
            </button>
            <button
              type="submit"
              className="w-[45%] px-[16px] py-[8px] bg-black-100 text-white border rounded-[20px] font-poppins-semibold"
            >
              Save
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default AdminNewModal;
