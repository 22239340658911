import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useCopyToClipboard } from '../../hooks/useCopyToClipboard';
import { editWalletInfo } from '../../actions/wallet';
import EditWallet from './EditWallet';
import DeleteWallet from './DeleteWallet';

const WalletCard = ({ walletInfo, canEdit = true, showWalletList = null }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [modalSection, setModalSection] = useState('');
  const { copyText } = useCopyToClipboard();
  const dispatch = useDispatch();
  const dropdownOptions = [
    {
      name: 'Select',
      icon: 'flag-outline',
    },
    {
      name: 'Edit',
      icon: 'create-outline',
    },
    {
      name: 'Delete',
      icon: 'trash-outline',
    },
    {
      name: 'Copy Address',
      icon: 'copy-outline',
    },
  ];

  useEffect(() => {
    if (selectedOption) {
      var walletDetail = JSON.parse(JSON.stringify(walletInfo));
      if (selectedOption === 'Select') {
        walletDetail.is_active = !walletDetail.is_active;
      } else if (selectedOption === 'Edit') {
        setModalSection('edit');
      } else if (selectedOption === 'Delete') {
        setModalSection('delete');
      } else if (selectedOption === 'Copy Address') {
        copyText(
          walletInfo.wallet_address,
          'Successfully copied wallet address'
        );
      }

      if (selectedOption === 'Select') dispatch(editWalletInfo(walletDetail));
      setSelectedOption('');
      setShowDropdown(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption]);

  return (
    <>
      {modalSection === 'edit' ? (
        <EditWallet closeModal={setModalSection} walletDetail={walletInfo} />
      ) : modalSection === 'delete' ? (
        <DeleteWallet closeModal={setModalSection} walletDetail={walletInfo} />
      ) : null}
      <div className="flex flex-row justify-between py-[8px] border-b relative">
        {showDropdown ? (
          <div className="absolute top-[8px] right-[20px] z-20 flex flex-col px-[12px] py-[4px] bg-white-f border border-black-100 rounded-lg">
            {dropdownOptions.map((option) => (
              <div
                key={option.icon}
                onClick={() => setSelectedOption(option.name)}
                className="cursor-pointer flex flex-row items-center py-[8px] border-b"
              >
                {walletInfo.is_active && option.name === 'Select' ? null : (
                  <ion-icon
                    name={option.icon}
                    style={{ width: '24px', height: '24px' }}
                  ></ion-icon>
                )}
                <p className="text-sm font-poppins-semibold ml-[4px]">
                  {walletInfo.is_active && option.name === 'Select'
                    ? 'Unselect'
                    : option.name}
                </p>
              </div>
            ))}
          </div>
        ) : null}
        <div className="flex flex-col">
          <div className="flex flex-row items-start">
            <ion-icon
              name="wallet-outline"
              style={{ width: '24px', height: '24px' }}
            ></ion-icon>
            <div className="flex flex-col ml-[8px]">
              <p className="text-sm font-poppins-semibold">
                {walletInfo.wallet_name}
              </p>
              <p className="text-sm">{walletInfo.wallet_address}</p>
            </div>
          </div>
          <div className="flex flex-row items-center">
            <ion-icon
              name="link-outline"
              style={{ width: '24px', height: '24px' }}
            ></ion-icon>
            <p className="text-sm ml-[8px]">{walletInfo.wallet_platform}</p>
          </div>
        </div>
        {canEdit ? (
          <button
            className="flex"
            type="button"
            onClick={() => setShowDropdown(!showDropdown)}
          >
            <ion-icon
              name="ellipsis-vertical"
              style={{ width: '24px', height: '24px' }}
            ></ion-icon>
          </button>
        ) : (
          <button
            className="flex bg-black-100 p-[8px] rounded-lg h-fit"
            type="button"
            onClick={() => showWalletList('wallet_list')}
          >
            <ion-icon
              name="wallet-outline"
              style={{ width: '24px', height: '24px', color: '#f7f7f7' }}
            ></ion-icon>
          </button>
        )}
      </div>
    </>
  );
};

export default WalletCard;
