import { createSlice } from '@reduxjs/toolkit';
import {
  editPlayer,
  fetchPlayerDetail,
  fetchPlayerList,
  fetchPlayerTradingHistory,
  fetchPlayerTransactionHistory,
  fetchRecruiterDetail,
  fetchRecruiterList,
} from '../actions/players';

const initialState = {
  playerList: [],
  recruiterList: [],
  recruiterMemberList: [],
  recruiterPageData: {
    page: 0,
    search: '',
    dataLimit: 10,
  },
  memberPageData: {
    member_page: 0,
    search: '',
    dataLimit: 10,
  },
  pageData: {
    page: 0,
    search: '',
    dataLimit: 10,
  },
  detailPageData: {
    season_page: 0,
    search: '',
    dataLimit: 10,
  },
  transactionPageData: {
    page: 0,
    search: '',
    dataLimit: 10,
  },
  ticketPageData: {
    page: 0,
    search: '',
    dataLimit: 10,
  },
  recruiterPages: 0,
  seasonPages: 0,
  memberPages: 0,
  totalPages: 0,
  transactionPages: 0,
  ticketPages: 0,
  selectedPlayerDetail: null,
  selectedRecruiterDetail: null,
  selectedSeasonSummary: null,
  selectedPlayerTrsHistory: null,
  selectedPlayerTicketHistory: null,
  detailSection: ['Basic Info', 'Transaction History', 'Trading History'],
  selectedSection: 'Basic Info',
  isLoading: false,
};

export const players = createSlice({
  name: 'players',
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setSelectedSection: (state, action) => {
      state.selectedSection = action.payload;
    },
    setPageData: (state, action) => {
      state.pageData = { ...state.pageData, ...action.payload };
    },
    setRecruiterPageData: (state, action) => {
      state.recruiterPageData = {
        ...state.recruiterPageData,
        ...action.payload,
      };
    },
    setMemberPageData: (state, action) => {
      state.memberPageData = {
        ...state.memberPageData,
        ...action.payload,
      };
    },
    setDetailPageData: (state, action) => {
      state.detailPageData = { ...state.detailPageData, ...action.payload };
    },
    setTrsPageData: (state, action) => {
      state.transactionPageData = {
        ...state.transactionPageData,
        ...action.payload,
      };
    },
    setTicketPageData: (state, action) => {
      state.ticketPageData = { ...state.ticketPageData, ...action.payload };
    },
    setSelectedPlayerDetail: (state, action) => {
      state.selectedPlayerDetail = action.payload;
    },
    resetSection: (state, action) => {
      state.selectedSection = initialState.selectedSection;
    },
    resetPageData: (state, action) => {
      state[action.payload] = initialState[action.payload];
    },
  },
  extraReducers: {
    [fetchPlayerList.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchPlayerList.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.playerList = payload.trader_list;
      state.totalPages = payload.pages;
    },
    [fetchPlayerList.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [fetchPlayerDetail.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchPlayerDetail.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.selectedPlayerDetail = payload.trader_detail;
    },
    [fetchPlayerDetail.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [fetchRecruiterList.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchRecruiterList.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.recruiterList = payload.recruiter_list;
      state.recruiterPages = payload.pages;
    },
    [fetchRecruiterList.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [fetchRecruiterDetail.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchRecruiterDetail.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.selectedRecruiterDetail = payload.recruiter_detail;
      state.recruiterMemberList = payload.member_list;
      state.memberPages = payload.pages;
    },
    [fetchRecruiterDetail.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [fetchPlayerTransactionHistory.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchPlayerTransactionHistory.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.selectedPlayerTrsHistory = payload.transaction_list;
      state.transactionPages = payload.pages;
    },
    [fetchPlayerTransactionHistory.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [fetchPlayerTradingHistory.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchPlayerTradingHistory.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.selectedPlayerTicketHistory = payload.trade_list;
      state.ticketPages = payload.pages;
    },
    [fetchPlayerTradingHistory.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [editPlayer.pending]: (state) => {
      state.isLoading = true;
    },
    [editPlayer.rejected]: (state) => {
      state.isLoading = false;
    },
    [editPlayer.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      if (state.selectedPlayerDetail)
        state.selectedPlayerDetail = payload.trader_detail;
      if (state.selectedRecruiterDetail)
        state.selectedRecruiterDetail = payload.trader_detail;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setIsLoading,
  setPageData,
  setSelectedSection,
  setDetailPageData,
  setTrsPageData,
  setTicketPageData,
  setRecruiterPageData,
  setMemberPageData,
  setSelectedPlayerDetail,
  resetSection,
  resetPageData,
} = players.actions;

export default players.reducer;
