import Modal from '../Modal/Modal';

const ActivePlayersModal = ({ closeModal }) => {
  return (
    <Modal title="Total Active Traders" closeModal={closeModal}>
      <ul className="list-decimal text-sm ml-[16px]">
        <li>Traders that did more than one transaction</li>
      </ul>
    </Modal>
  );
};

export default ActivePlayersModal;
