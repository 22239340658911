import { createAsyncThunk } from '@reduxjs/toolkit';
import { serverAPI } from '../utils/API';
import { enqueueSnackbar } from 'notistack';

export const fetchContactList = createAsyncThunk(
  'contact/contactList',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.get('adminContactPlatformList', {
        params,
      });
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const createContact = createAsyncThunk(
  'contact/createContact',
  async (contactData, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.post(
        'createContactPlatform',
        contactData
      );
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const editContact = createAsyncThunk(
  'contact/editContact',
  async (contactData, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.put('editContactPlatform', contactData);
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteContact = createAsyncThunk(
  'contact/deleteContact',
  async (contactID, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.delete('deleteContactPlatform', {
        data: contactID,
      });
      data.id = contactID._id;
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);
