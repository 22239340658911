const getPageTitle = (path) => {
  if (path.includes('admins') || path.includes('admin')) return 'Admins';
  else if (path.includes('option')) return 'Binary Option';
  else if (path.includes('transactions') || path.includes('transaction'))
    return 'Transactions';
  else if (path.includes('recruiters') || path.includes('recruiter'))
    return 'Recruiters';
  else if (path.includes('traders')) return 'Traders';
  else if (path.includes('content')) return 'Pages Content';
  else if (path.includes('contact')) return 'Contact';
  else return 'Dashboard';
};

const getPathName = (page) => {
  page = page.toLowerCase();
  if (page.includes('admin')) return '/admins';
  else if (page.includes('currency')) return '/option';
  else if (page.includes('transactions') || page.includes('transaction'))
    return '/transactions';
  else if (page.includes('trader')) return '/traders';
  else if (page.includes('recruiters')) return '/recruiters';
  else return 'Dashboard';
};

export { getPageTitle, getPathName };
