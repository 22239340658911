import { createAsyncThunk } from '@reduxjs/toolkit';
import { serverAPI } from '../utils/API';
import { enqueueSnackbar } from 'notistack';

export const fetchAdminList = createAsyncThunk(
  'admin/adminList',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.get('adminList', { params });
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const fetchAdminInfo = createAsyncThunk(
  'admin/adminInfo',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.get('adminInfo', { params });
      return data.admin_data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const registerAdmin = createAsyncThunk(
  'admin/registerAdmin',
  async (adminData, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.post('registerAdmin', adminData);
      var details = data.details;
      details.password = adminData.password;
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const editAdmin = createAsyncThunk(
  'admin/editAdmin',
  async (adminData, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.put('editAdmin', adminData);
      var details = data.details;
      if (adminData.password) details.password = adminData.password;
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteAdmin = createAsyncThunk(
  'admin/deleteAdmin',
  async (adminID, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.delete('deleteAdmin', { data: adminID });
      data.id = adminID._id;
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const changePasswordAdmin = createAsyncThunk(
  'admin/changePassword',
  async (adminData, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await serverAPI.put('changePassword', adminData);
      if (data.token) localStorage.setItem('USER_KEY', data.token);
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);
