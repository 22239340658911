import { Modal as ModalMUI } from '@mui/material';

const Modal = ({ title, closeModal, children }) => {
  const handleClose = () => closeModal(false);

  return (
    <ModalMUI open={true} onClose={handleClose}>
      <div className="w-[85%] lg:w-[25%] absolute z-100 bg-white-f p-[24px] rounded-lg shadow-modal top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <div className="flex flex-row justify-between items-center border-b border-b-grey-20 pb-[24px]">
          <h3 className="text-lg font-poppins-bold">{title}</h3>
          <button className="bg-transparent" onClick={handleClose}>
            <ion-icon
              name="close"
              style={{ width: '20px', height: '20px' }}
            ></ion-icon>
          </button>
        </div>
        <section className="mt-[24px] max-h-[60vh] overflow-auto">
          {children}
        </section>
      </div>
    </ModalMUI>
  );
};

export default Modal;
