import { useEffect, useState } from 'react';
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { convertToHTML, convertFromHTML } from 'draft-convert';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment/moment';
import { setFAQBanner } from '../../slices/contentSlice';
import { enqueueSnackbar } from 'notistack';
import ImageUpload from '../Common/ImageUpload';
import { createUpdateContent } from '../../actions/content';
import getToastMessage from '../../utils/toastMessage';

const ContentWYSIWYG = () => {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const { selectedSection, FAQBanner, contentList } = useSelector(
    (state) => state.content
  );
  const [convertedContent, setConvertedContent] = useState(null);
  const [previewBanner, setPreviewBanner] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    let html = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(html);
  }, [editorState]);

  useEffect(() => {
    if (previewBanner) {
      dispatch(setFAQBanner(previewBanner));
    }
  }, [dispatch, previewBanner]);

  useEffect(() => {
    if (contentList && contentList.length && contentList[0].content) {
      const content = convertFromHTML(contentList[0].content);
      setEditorState(() => EditorState.createWithContent(content));
    } else {
      setEditorState(() => EditorState.createEmpty());
    }
  }, [contentList]);

  const showToast = (message, variant = 'warning') => {
    enqueueSnackbar(message ? message : 'Contents are posted and published', {
      variant,
    });
  };

  const saveContent = async () => {
    if (!FAQBanner) {
      return showToast(`Please provide ${selectedSection} Banner`);
    }

    const contentData = [
      {
        _id:
          contentList && contentList.length && contentList[0]._id
            ? contentList[0]._id
            : '',
        content_type: selectedSection,
        banner: FAQBanner,
        title: selectedSection,
        content: convertedContent,
        sortOrder: 0,
      },
    ];
    console.log(contentData, '===content');
    const done = await dispatch(createUpdateContent(contentData));
    if (done) {
      const { message, variant } = getToastMessage(done);
      if (variant === 'success') showToast(message, variant);
    }
  };

  return (
    <div>
      <ImageUpload type="faq" name="faq-banner" setImage={setPreviewBanner} />
      <h3 className="font-poppins-semibold text-base">{selectedSection}</h3>
      <div
        className="flex flex-col w-full lg:flex-row lg:gap-[16px] my-[16px]"
        onClick={() => {
          let imgBtn = document.getElementById('image-upload');
          imgBtn.click();
        }}
      >
        <div
          className={`flex justify-center items-center h-[162px] w-full lg:w-[75%] ${
            FAQBanner ? 'bg-transparent' : 'bg-black-100'
          }`}
        >
          {FAQBanner ? (
            <img
              src={FAQBanner}
              alt="faq banner"
              className="w-full h-full object-cover"
            />
          ) : (
            <button
              type="button"
              className="w-[80%] lg:w-[60%] font-poppins-semibold px-[16px] py-[8px] border-[0.5px] border-black-100 rounded-[20px] bg-white"
            >
              Upload Image
            </button>
          )}
        </div>
        {contentList.length ? (
          <p className="font-poppins-semibold lg:w-[20%] lg:text-sm mt-[16px] lg:mt-0">
            Last update at:{' '}
            <span className="font-poppins">
              {moment(contentList[0].updatedAt).format('DD-MM-YYYY, HH:mm')}
            </span>
          </p>
        ) : null}
      </div>
      <Editor
        editorState={editorState}
        onEditorStateChange={setEditorState}
        wrapperClassName="wrapper-class"
        editorClassName="editor-class"
        toolbarClassName="toolbar-class"
        toolbar={{
          options: ['history', 'inline', 'blockType', 'list', 'link', 'image'],
          blockType: {
            options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
          },
        }}
      />
      <div className="w-full flex justify-center items-center mt-[32px]">
        <button
          type="button"
          onClick={saveContent}
          className="text-center bg-black-100 font-poppins-semibold text-white py-[10px] rounded-[20px] w-[70%] lg:w-[40%]  "
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default ContentWYSIWYG;
