import { createAsyncThunk } from '@reduxjs/toolkit';
import { serverAPI } from '../utils/API';
import { enqueueSnackbar } from 'notistack';
import { setAdminBalance } from '../slices/dashboardSlice';

export const checkReceiverName = createAsyncThunk(
  'coins/checkReceiverName',
  async (email, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.post('adminCheckReceiverName', email);
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const sendCoinInternally = createAsyncThunk(
  'coins/sendCoinInternal',
  async (coinData, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await serverAPI.post(
        'adminSendLuckyCoinInternal',
        coinData
      );
      if (data && data.new_admin_balance_data) {
        dispatch(setAdminBalance(data.new_admin_balance_data));
      }
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const sendCoinExternally = createAsyncThunk(
  'coins/sendCoinExternal',
  async (coinData, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await serverAPI.post(
        'adminSendLuckyCoinExternal',
        coinData
      );
      if (data && data.new_admin_balance_data) {
        dispatch(setAdminBalance(data.new_admin_balance_data));
      }
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const sendCoinOTP = createAsyncThunk(
  'coins/sendCoinOTP',
  async (coinData, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.post('adminSendOTP', {});
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);
