import { createSlice } from '@reduxjs/toolkit';
import {
  addWinningNumbers,
  createDrawdown,
  createNextDrawdown,
  createUpdateAnnouncement,
  createUpdateDrawdate,
  createUpdateFreezeDate,
  drawdownTopUpPrize,
  editPrizeDistribution,
  fetchDrawdownInfo,
  fetchDrawdownList,
  resumePausePrize,
} from '../actions/drawdown';

const initialState = {
  drawdownList: [],
  seasonID: '',
  latestDrawdownInfo: null,
  selectedDrawdownInfo: null,
  selectedDrawdownID: '',
  isLoading: false,
};

export const drawdown = createSlice({
  name: 'drawdown',
  initialState,
  reducers: {
    setSelectedDrawdownID: (state, action) => {
      state.selectedDrawdownID = action.payload;
    },
    clearState: () => initialState,
  },
  extraReducers: {
    [fetchDrawdownList.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchDrawdownList.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.drawdownList = payload.drawdown_list;
      if (payload.drawdown_list.length) {
        state.latestDrawdownInfo =
          payload.drawdown_list[payload.drawdown_list.length - 1];
        const currentActive = payload.drawdown_list.filter(
          (draw) => draw.status === 'Active'
        )[0];
        const currentFinished = payload.drawdown_list.filter(
          (draw) => draw.status === 'Finished'
        );

        const urlParams = new URLSearchParams(window.location.search);
        const drawID = urlParams.get('draw_id');

        if (!drawID) {
          currentActive
            ? (state.selectedDrawdownID = currentActive.drawdown.id)
            : currentFinished
            ? (state.selectedDrawdownID =
                currentFinished[currentFinished.length - 1].drawdown.id)
            : (state.selectedDrawdownID = 'D1');
        }
      }
      state.seasonID = payload.lottery_season_id;
    },
    [fetchDrawdownList.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [fetchDrawdownInfo.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchDrawdownInfo.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.selectedDrawdownInfo = payload;
    },
    [fetchDrawdownInfo.rejected]: (state) => {
      state.isLoading = false;
    },
    [createDrawdown.pending]: (state) => {
      state.isLoading = true;
    },
    [createDrawdown.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.drawdownList = [...state.drawdownList, payload.details];
      state.selectedDrawdownInfo = payload.details;
    },
    [createDrawdown.rejected]: (state) => {
      state.isLoading = false;
    },
    [createNextDrawdown.pending]: (state) => {
      state.isLoading = true;
    },
    [createNextDrawdown.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.drawdownList = [...state.drawdownList, payload.details];
      state.selectedDrawdownInfo = payload.details;
      state.latestDrawdownInfo = payload.details;
    },
    [createNextDrawdown.rejected]: (state) => {
      state.isLoading = false;
    },
    [resumePausePrize.pending]: (state) => {
      state.isLoading = true;
    },
    [resumePausePrize.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      const { details } = payload;
      state.selectedDrawdownInfo = details;
    },
    [resumePausePrize.rejected]: (state) => {
      state.isLoading = false;
    },
    [drawdownTopUpPrize.pending]: (state) => {
      state.isLoading = true;
    },
    [drawdownTopUpPrize.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      const { details } = payload;
      state.selectedDrawdownInfo = details;
    },
    [drawdownTopUpPrize.rejected]: (state) => {
      state.isLoading = false;
    },
    [createUpdateDrawdate.pending]: (state) => {
      state.isLoading = true;
    },
    [createUpdateDrawdate.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      const { details } = payload;
      const drawList = state.drawdownList;
      const idx = drawList.findIndex((d) => d._id === details._id);
      if (idx >= 0) {
        drawList.splice(idx, 1, details);
        state.drawdownList = drawList;
      }

      state.selectedDrawdownInfo = details;
    },
    [createUpdateDrawdate.rejected]: (state) => {
      state.isLoading = false;
    },
    [createUpdateFreezeDate.pending]: (state) => {
      state.isLoading = true;
    },
    [createUpdateFreezeDate.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      const { details } = payload;
      state.selectedDrawdownInfo = details;
    },
    [createUpdateFreezeDate.rejected]: (state) => {
      state.isLoading = false;
    },
    [createUpdateAnnouncement.pending]: (state) => {
      state.isLoading = true;
    },
    [createUpdateAnnouncement.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      const { details } = payload;
      state.selectedDrawdownInfo = details;
    },
    [createUpdateAnnouncement.rejected]: (state) => {
      state.isLoading = false;
    },
    [addWinningNumbers.pending]: (state) => {
      state.isLoading = true;
    },
    [addWinningNumbers.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      const { details } = payload;
      state.selectedDrawdownInfo = details;
    },
    [addWinningNumbers.rejected]: (state) => {
      state.isLoading = false;
    },
    [editPrizeDistribution.pending]: (state) => {
      state.isLoading = true;
    },
    [editPrizeDistribution.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      const { details } = payload;
      state.selectedDrawdownInfo = details;
    },
    [editPrizeDistribution.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

export const { setSelectedDrawdownID, clearState } = drawdown.actions;

export default drawdown.reducer;
