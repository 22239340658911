import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { enqueueSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';
import { tradeGroup } from '../../utils/validationForm';
import Modal from '../Modal/Modal';
import Input from '../Input/Input';
import getToastMessage from '../../utils/toastMessage';
import { createUpdateTradeGroup } from '../../actions/binary';
import { useParams } from 'react-router-dom';
import { setSelectedTradeGroup } from '../../slices/binarySlice';
const TradeGroupModal = ({ closeModal }) => {
  const { register, handleSubmit, reset, getValues } = useForm({
    resolver: yupResolver(tradeGroup),
  });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { selectedTradeGroupDetail } = useSelector((state) => state.binary);
  const { id } = useParams();
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    const done = await dispatch(createUpdateTradeGroup(data));
    if (done) {
      const { message, variant } = getToastMessage(done);
      if (variant === 'success') {
        enqueueSnackbar(message, { variant });
        closeModal(false);
        reset();
      }
    }
  };

  const deleteGroup = async () => {
    const data = getValues();
    data.is_deleted = true;
    const done = await dispatch(createUpdateTradeGroup(data));
    if (done) {
      const { message, variant } = getToastMessage(done);
      if (variant === 'success') {
        enqueueSnackbar(message, { variant });
        closeModal(false);
      }
    }
  };

  useEffect(() => {
    let payload = { binary_option_id: id };
    if (selectedTradeGroupDetail) {
      payload[`trade_group_id`] = selectedTradeGroupDetail._id;
      payload = {
        ...payload,
        ...JSON.parse(JSON.stringify(selectedTradeGroupDetail)),
      };
    }
    reset(payload);

    return () => {
      dispatch(setSelectedTradeGroup(null));
    };
  }, [selectedTradeGroupDetail, reset, id, dispatch]);

  const DeleteTradeGroupModal = () => {
    return (
      <Modal closeModal={setShowDeleteModal} title="Delete Trade Group">
        <p>Delete this trade group?</p>
        <div className="flex flex-row justify-between items-center mt-[24px]">
          <button
            onClick={() => setShowDeleteModal(false)}
            type="button"
            className="w-[45%] px-[16px] py-[8px] text-red border rounded-[20px] font-poppins-semibold"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={() => deleteGroup()}
            className="w-[45%] px-[16px] py-[8px] bg-black-100 text-white border rounded-[20px] font-poppins-semibold"
          >
            Delete
          </button>
        </div>
      </Modal>
    );
  };

  return (
    <>
      {showDeleteModal ? <DeleteTradeGroupModal /> : null}
      <Modal title="Add/Edit Trade Group" closeModal={closeModal}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            name="name"
            placeholder="Group name"
            title="Group Name"
            register={register}
          />
          <Input
            name="min_trade"
            placeholder="CKU"
            title="Min Bet"
            type="number"
            register={register}
          />
          <Input
            name="max_trade"
            placeholder="CKU"
            title="Max Bet"
            type="number"
            register={register}
          />
          <div className="flex flex-row justify-between items-center mt-[24px]">
            <button
              onClick={() =>
                selectedTradeGroupDetail ? setShowDeleteModal(true) : reset()
              }
              type="button"
              className="w-[45%] px-[16px] py-[8px] text-red border rounded-[20px] font-poppins-semibold"
            >
              {selectedTradeGroupDetail ? 'Delete Group' : 'Discard All'}
            </button>
            <button
              type="submit"
              className="w-[45%] px-[16px] py-[8px] bg-black-100 text-white border rounded-[20px] font-poppins-semibold"
            >
              {selectedTradeGroupDetail ? 'Save' : 'Create'}
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default TradeGroupModal;
