import { useEffect, useRef, useState } from 'react';
import Modal from './Modal';
import ImageUpload from '../Common/ImageUpload';
import { useDispatch } from 'react-redux';
import { createContact, editContact } from '../../actions/contact';
import getToastMessage from '../../utils/toastMessage';
import { enqueueSnackbar } from 'notistack';
import ContactDeleteModal from './ContactDeleteModal';

const ContactNewModal = ({ closeModal, selectedContact = null }) => {
  const imgInput = useRef();
  const [previewImg, setPreviewImg] = useState();
  const [deleteContactID, setDeleteContactID] = useState(null);
  const [contactForm, setContactForm] = useState({
    icon: null,
    iconFile: null,
    name: '',
    link: '',
  });
  const dispatch = useDispatch();

  const onChangeFile = (ev) => {
    if (ev.target.files[0]) {
      setContactForm((prevState) => ({
        ...prevState,
        iconFile: ev.target.files[0],
        icon: URL.createObjectURL(ev.target.files[0]),
      }));
    }
  };

  const handleInputChange = (field, ev) => {
    setContactForm((prevState) => ({ ...prevState, [field]: ev.target.value }));
  };

  const submitNewContact = async (ev) => {
    if (ev) ev.preventDefault();
    if (
      contactForm.link &&
      contactForm.name &&
      (contactForm.iconFile || contactForm.icon)
    ) {
      if (!previewImg && contactForm.icon.includes('blob:')) {
        setPreviewImg(contactForm.iconFile);
      } else if (
        (previewImg && typeof previewImg === 'string') ||
        !contactForm.icon.includes('blob:')
      ) {
        var contactData = JSON.parse(JSON.stringify(contactForm));
        if (contactForm.icon.includes('blob')) {
          contactData.icon = previewImg;
          delete contactData.iconFile;
        }

        const done =
          selectedContact && selectedContact._id
            ? await dispatch(editContact(contactData))
            : await dispatch(createContact(contactData));

        if (done) {
          const { message, variant } = getToastMessage(done);
          if (variant === 'success') {
            enqueueSnackbar(message, { variant });
            closeModal(false);
          }
        }
      }
    }
  };

  const onRemoveIcon = () => {
    setContactForm((prevState) => ({ ...prevState, icon: '' }));
  };

  useEffect(() => {
    if (previewImg && typeof previewImg === 'string') {
      submitNewContact();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previewImg]);

  useEffect(() => {
    if (selectedContact && selectedContact.name) {
      setContactForm(selectedContact);
    }
  }, [selectedContact]);

  useEffect(() => {
    if (deleteContactID === 1) closeModal(false);
  }, [closeModal, deleteContactID]);

  return (
    <>
      {deleteContactID ? (
        <ContactDeleteModal
          closeModal={setDeleteContactID}
          contactID={deleteContactID}
        />
      ) : null}
      <Modal
        title={selectedContact ? 'Edit Contact' : 'New Contact'}
        closeModal={closeModal}
      >
        <ImageUpload
          fileToUpload={previewImg}
          setImage={setPreviewImg}
          type="contact_platform"
        />
        <input
          ref={imgInput}
          className="hidden"
          type="file"
          onChange={onChangeFile}
          accept="image/*"
        />
        <form onSubmit={(ev) => submitNewContact(ev)}>
          <div className="flex flex-col">
            <label className="text-sm">Icon</label>
            <div
              className={`relative flex flex-col w-[92px] h-[92px] rounded-lg mb-[18px] cursor-pointer ${
                contactForm.icon ? 'bg-black-100' : 'bg-grey-banner'
              }`}
            >
              <button
                type="button"
                className="flex absolute top-[4px] right-[4px] bg-black-100 opacity-25 z-10"
                onClick={onRemoveIcon}
              >
                <ion-icon
                  name="close-outline"
                  style={{ color: 'white', width: '12px', height: '12px' }}
                ></ion-icon>
              </button>
              {contactForm.icon ? (
                <img
                  className="w-full h-full object-contain"
                  src={contactForm.icon}
                  alt="preview"
                  onClick={() => imgInput.current.click()}
                />
              ) : (
                <div
                  className="flex flex-col items-center m-auto"
                  onClick={() => imgInput.current.click()}
                >
                  <ion-icon
                    name="images-outline"
                    style={{ width: '16px', height: '16px' }}
                  ></ion-icon>
                  <p className="text-sm">Upload Icon</p>
                </div>
              )}
            </div>
            <div className={`flex flex-col mb-[18px] w-full`}>
              <label className="text-sm">Platform Name</label>
              <input
                type="text"
                placeholder="Name"
                onChange={(ev) => handleInputChange('name', ev)}
                defaultValue={contactForm.name}
                className="text-sm py-[11px] px-[8px] border border-black rounded-[6px] outline-0"
              />
            </div>
            <div className={`flex flex-col mb-[18px] w-full`}>
              <label className="text-sm">Link</label>
              <input
                type="text"
                placeholder="Link"
                defaultValue={contactForm.link}
                onChange={(ev) => handleInputChange('link', ev)}
                className="text-sm py-[11px] px-[8px] border border-black rounded-[6px] outline-0"
              />
            </div>
          </div>
          <div className="flex flex-row justify-between items-center mt-[24px]">
            <button
              type="button"
              onClick={() => {
                if (selectedContact && selectedContact._id) {
                  setDeleteContactID(selectedContact._id);
                }
              }}
              className="w-[45%] px-[16px] py-[8px] text-red border rounded-[20px] font-poppins-semibold"
            >
              Delete
            </button>
            <button
              type="submit"
              className="w-[45%] px-[16px] py-[8px] bg-black-100 text-white border rounded-[20px] font-poppins-semibold"
            >
              Save
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default ContactNewModal;
