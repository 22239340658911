export const checkSameDate = (firstDate, secondDate) => {
  firstDate = new Date(firstDate);
  secondDate = new Date(secondDate);

  if (
    firstDate.getDate() === secondDate.getDate() &&
    firstDate.getMonth() === secondDate.getMonth() &&
    firstDate.getFullYear() === secondDate.getFullYear()
  ) {
    return true;
  }

  return false;
};

export const formatTimeFromSeconds = (seconds) => {
  if (typeof seconds !== 'number' || isNaN(seconds) || seconds <= 0) {
    return false;
  }
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const formattedSeconds =
    remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds;

  return `${formattedMinutes}:${formattedSeconds}`;
};
