import { createSlice } from '@reduxjs/toolkit';
import { createUpdateContent, fetchContentList } from '../actions/content';

const initialState = {
  contentList: [],
  contentSections: [
    'FAQ',
    'About Us',
    'Privacy Policy',
    'Responsible Trading',
    'Service & Terms',
  ],
  FAQBanner: '',
  selectedSection: 'FAQ',
  isLoading: false,
};

export const content = createSlice({
  name: 'content',
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setSelectedSection: (state, action) => {
      state.selectedSection = action.payload;
      state.contentList = [];
      state.FAQBanner = '';
    },
    setContentList: (state, action) => {
      state.contentList = action.payload;
    },
    setFAQBanner: (state, action) => {
      state.FAQBanner = action.payload;
    },
  },
  extraReducers: {
    [fetchContentList.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchContentList.fulfilled]: (state, { payload }) => {
      const { pages_content_list } = payload;
      state.isLoading = false;
      state.contentList = payload.pages_content_list;
      if (pages_content_list.length && pages_content_list[0].banner) {
        state.FAQBanner = pages_content_list[0].banner;
      }
    },
    [fetchContentList.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [createUpdateContent.pending]: (state) => {
      state.isLoading = true;
    },
    [createUpdateContent.rejected]: (state) => {
      state.isLoading = false;
    },
    [createUpdateContent.fulfilled]: (state, { payload }) => {
      const { pages_content_list } = payload;
      state.isLoading = false;
      state.contentList = payload.pages_content_list;
      if (pages_content_list.length && pages_content_list[0].banner) {
        state.FAQBanner = pages_content_list[0].banner;
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setIsLoading,
  setSelectedSection,
  setContentList,
  setFAQBanner,
} = content.actions;

export default content.reducer;
