const ConfirmTNC = ({ setTNCState, TNCState = false }) => {
  return (
    <div className="flex flex-row mb-[16px]">
      <button
        type="button"
        className="flex mr-[8px]"
        onClick={() => setTNCState(!TNCState)}
      >
        <ion-icon
          name={TNCState ? 'checkbox-outline' : 'square-outline'}
          style={{ color: '#3e3e3e', width: '24px', height: '24px' }}
        ></ion-icon>
      </button>
      <p className="text-sm">
        I understand that sending to the wrong address is irreversible, and
        Duotrx cannot assist in reversing the action.
      </p>
    </div>
  );
};

export default ConfirmTNC;
