import { useEffect, useState } from 'react';
import Modal from './Modal';
import { useDispatch, useSelector } from 'react-redux';
import { setPageData } from '../../slices/ticketSlice';

const SeasonFilterModal = ({ closeModal }) => {
  const [selected, setSelected] = useState('');
  const pageData = useSelector((state) => state.ticket.pageData);
  const dispatch = useDispatch();
  const options = ['All', 'Hidden', 'Active', 'Finished'];
  useEffect(() => {
    if (pageData.season_filter) {
      setSelected(pageData.season_filter);
    }
  }, [pageData]);

  const applyFilter = () => {
    if (selected !== pageData.season_filter) {
      dispatch(setPageData({ season_filter: selected }));
    }
    closeModal(false);
  };

  const clearAll = () => {
    dispatch(setPageData({ season_filter: '' }));
    closeModal(false);
  };

  return (
    <Modal title="Season Filter" closeModal={closeModal}>
      <div className="flex flex-wrap gap-[8px] w-full justify-between">
        {options.map((option, idx) => (
          <button
            key={idx}
            onClick={() => setSelected(option)}
            className={`border-[0.5px] border-black-100 rounded-[4px] py-[6px] font-poppins-semibold min-w-[48%] ${
              option === selected
                ? 'bg-black-100 text-white'
                : 'bg-white text-black-100'
            }`}
          >
            {option}
          </button>
        ))}
      </div>
      <div className="flex flex-row justify-between items-center mt-[24px]">
        <button
          onClick={clearAll}
          type="button"
          className="w-[45%] px-[16px] py-[8px] text-red border rounded-[20px] font-poppins-semibold"
        >
          Discard All
        </button>
        <button
          onClick={applyFilter}
          type="button"
          className="w-[45%] px-[16px] py-[8px] bg-black-100 text-white border rounded-[20px] font-poppins-semibold"
        >
          Apply
        </button>
      </div>
    </Modal>
  );
};

export default SeasonFilterModal;
