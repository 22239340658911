import { createSlice } from '@reduxjs/toolkit';
import {
  createOption,
  createUpdateTradeGroup,
  editOption,
  editOptionStatus,
  fetchCoinList,
  fetchOptionDetail,
  fetchOptionList,
} from '../actions/binary';

const initialState = {
  optionList: [],
  coinList: [],
  tradeGroupList: [],
  selectedOptionDetail: null,
  selectedTradeGroupDetail: null,
  isLoading: false,
  totalPages: 0,
  pageData: {
    page: 0,
    search: '',
    dataLimit: 10,
  },
  availableInterval: [
    60, 180, 300, 900, 1800, 3600, 7200, 14400, 21600, 43200, 86400, 259200,
  ],
};

export const binary = createSlice({
  name: 'binary',
  initialState,
  reducers: {
    setPageData: (state, action) => {
      state.pageData = { ...state.pageData, ...action.payload };
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setSelectedTradeGroup: (state, action) => {
      state.selectedTradeGroupDetail = action.payload;
    },
    resetOptionDetail: (state, action) => {
      state.selectedOptionDetail = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOptionList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchOptionList.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.optionList = payload.binary_option_list;
        state.totalPages = payload.pages;
      })
      .addCase(fetchOptionList.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchOptionDetail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchOptionDetail.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.selectedOptionDetail = payload.binary_option;
      })
      .addCase(fetchOptionDetail.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchCoinList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchCoinList.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.coinList = payload.coin_list;
      })
      .addCase(fetchCoinList.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(createOption.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createOption.fulfilled, (state, { payload }) => {
        state.isLoading = false;
      })
      .addCase(createOption.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(editOption.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editOption.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.selectedOptionDetail = payload.details;
      })
      .addCase(editOption.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(editOptionStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editOptionStatus.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.selectedOptionDetail = payload.details;
      })
      .addCase(editOptionStatus.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(createUpdateTradeGroup.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createUpdateTradeGroup.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.selectedOptionDetail = payload.details;
      })
      .addCase(createUpdateTradeGroup.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const {
  setPageData,
  setIsLoading,
  setSelectedTradeGroup,
  resetOptionDetail,
} = binary.actions;

export default binary.reducer;
