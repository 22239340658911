import { thousandSeparator } from '../../utils/math';
import { resetPageData, setTicketPageData } from '../../slices/playersSlice';
import moment from 'moment/moment';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import EditPlayerModal from './EditPlayerModal';
import { enqueueSnackbar } from 'notistack';
import { useSearchParams } from 'react-router-dom';
import Wallet from '../Wallet/Wallet';

const PlayerBasicInfo = () => {
  const { selectedPlayerDetail } = useSelector((state) => state.players);
  const dispatch = useDispatch();
  const [editModal, setEditModal] = useState(false);
  const [sendLCModal, setSendLCModal] = useState(false);
  const [, setSearchParams] = useSearchParams();
  const fields = [
    {
      placeholder: 'Email',
      field: 'email',
    },
    {
      placeholder: 'Trader ID',
      field: 'player_id',
    },
    // {
    //   placeholder: 'Phone',
    //   field: 'phone_number',
    // },
    {
      placeholder: 'Trader Status',
      field: 'status',
    },
    {
      placeholder: 'Registered Date',
      field: 'createdAt',
    },
    {
      placeholder: 'CKU Wallet Balance',
      field: 'cku_balance',
    },
    {
      placeholder: 'Amount Purchase',
      field: 'total_trades',
    },
    {
      placeholder: 'Win History',
      field: 'winning_trades',
    },
    {
      placeholder: 'Referral Link',
      field: 'referral_tag',
    },
    {
      placeholder: "Trader's CKU Wallet",
      field: 'cku_wallet_address',
    },
  ];

  useEffect(() => {
    return () => {
      dispatch(resetPageData('detailPageData'));
    };
  }, [dispatch]);

  return (
    <>
      {editModal ? (
        <EditPlayerModal closeModal={setEditModal} playerDetail={editModal} />
      ) : null}
      {sendLCModal ? (
        <Wallet
          defaultSection="Send CKU"
          closeModal={setSendLCModal}
          playerEmail={selectedPlayerDetail.email}
        />
      ) : null}
      {selectedPlayerDetail ? (
        <div>
          <div className="flex flex-col sm:flex-row sm:items-center font-poppins-semibold">
            <h3 className="text-base">Basic Info</h3>
            <div className="flex flex-row mt-[16px] sm:mt-0 sm:ml-[18%]">
              <button
                type="button"
                onClick={() => {
                  setEditModal({
                    _id: selectedPlayerDetail._id,
                    player_id: selectedPlayerDetail.player_id,
                    status: selectedPlayerDetail.status,
                    email: selectedPlayerDetail.email,
                    phone_number: selectedPlayerDetail.phone_number,
                  });
                }}
                className="px-[16px] py-[6px] border border-black-100 bg-white rounded-[20px] mr-[16px]"
              >
                Edit Trader
              </button>
              <button
                type="button"
                onClick={() => setSendLCModal(true)}
                className="px-[16px] py-[6px] border border-black-100 bg-white rounded-[20px]"
              >
                Send CKU
              </button>
            </div>
          </div>
          <div className="mt-[16px] mb-[24px]">
            {fields.map((field, idx) => (
              <div
                className="flex flex-row items-center w-full mb-[8px] text-sm"
                key={idx}
              >
                <label className="w-[45%] lg:w-[25%] font-poppins-semibold">
                  {field.placeholder}
                </label>
                <p
                  className={
                    field.field === 'total_trades' ||
                    field.field === 'winning_trades'
                      ? 'underline cursor-pointer'
                      : ''
                  }
                  onClick={() => {
                    if (
                      field.field === 'total_trades' ||
                      field.field === 'winning_trades'
                    ) {
                      setSearchParams({ player_section: 'Trading History' });
                      if (field.field === 'winning_trades')
                        dispatch(setTicketPageData({ ticket_status: 'Win' }));
                    }
                  }}
                >
                  {field.field === 'createdAt'
                    ? moment(selectedPlayerDetail.createdAt).format(
                        'DD-MM-YYYY, HH:mm:ss'
                      )
                    : field.field === 'cku_balance'
                    ? `CKU ${thousandSeparator(
                        selectedPlayerDetail[field.field]
                      )}`
                    : field.field === 'winning_trades'
                    ? selectedPlayerDetail[field.field].length
                    : selectedPlayerDetail[field.field]}
                </p>
                {field.field === 'referrer_tag' &&
                selectedPlayerDetail[field.field] ? (
                  <button
                    onClick={() => {
                      navigator.clipboard.writeText(
                        selectedPlayerDetail.referrer_tag
                      );
                      enqueueSnackbar('Sucessfully copied referral link', {
                        variant: 'success',
                      });
                    }}
                    className="flex ml-[5px]"
                  >
                    <ion-icon
                      name="copy-outline"
                      style={{ width: '18px', height: '18px' }}
                    ></ion-icon>
                  </button>
                ) : null}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <p>Trader data not found!</p>
      )}
    </>
  );
};

export default PlayerBasicInfo;
