import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAdminList } from '../actions/admin';
import { useIsSuperAdmin } from '../hooks/useAuth';
import { TableBody, TableHead, TableRow } from '@mui/material';
import AdminModalInfo from '../components/Modal/AdminModalInfo';
import LayoutAdmin from '../components/Layout/LayoutAdmin';
import AdminNewModal from '../components/Modal/AdminNewModal';
import StyledTableCell from '../components/Table/StyledTableCell';
import { setNewAdmins, setPageData } from '../slices/adminSlice';
import Loading from '../components/Common/Loading';
import StyledTableContainer from '../components/Table/StyledTableContainer';
import StyledTable from '../components/Table/StyledTable';
import Pagination from '../components/Table/Pagination';
import DataLimit from '../components/Table/DataLimit';

const AdminPage = () => {
  const [openAdminRole, setOpenAdminRole] = useState(false);
  const [openAdminNew, setOpenAdminNew] = useState(false);
  const { adminList, pageData, isLoading, totalPages } = useSelector(
    (state) => state.admin
  );

  const userInfo = useSelector((state) => state.auth.userInfo);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isSuperAdmin = useIsSuperAdmin();

  const openNewAdminModal = () => {
    if (isSuperAdmin) setOpenAdminNew(!openAdminNew);
  };

  const goToAdminDetail = (id) => {
    if (userInfo._id === id || isSuperAdmin) navigate(`/admin/${id}`);
  };

  const onSearchAdmin = (ev) => {
    if (ev.key === 'Enter' && ev.target.value !== pageData.search) {
      const search = ev.target.value;
      dispatch(setPageData({ search }));
      // setPageData((prevState) => ({ ...prevState, search }));
    }
  };

  const handlePageChange = (ev) => {
    if (ev.selected !== pageData.page) {
      dispatch(setPageData({ page: ev.selected }));
    }
  };

  useEffect(() => {
    dispatch(fetchAdminList(pageData));
    dispatch(setNewAdmins([]));
  }, [dispatch, pageData]);

  return (
    <>
      {isLoading ? <Loading /> : null}
      {openAdminNew ? <AdminNewModal closeModal={setOpenAdminNew} /> : null}
      {openAdminRole ? <AdminModalInfo closeModal={setOpenAdminRole} /> : null}
      <LayoutAdmin pageTitle="Admin List" pageSubtitle="Admin List">
        <div className="flex flex-col mb-[24px]">
          <label className="text-sm ">Search</label>
          <input
            placeholder="Name, Phone, Role or Email"
            onKeyDown={onSearchAdmin}
            className="bg-white w-full lg:w-1/3 p-[8px] border border-black-100 rounded-md text-sm outline-none"
          />
        </div>
        <div className="flex flex-row justify-between w-full items-end mb-[20px]">
          <DataLimit initialLimit={pageData.dataLimit} setter={setPageData} />
          <button
            type="button"
            onClick={openNewAdminModal}
            className={`w-fit bg-black-100 px-[16px] py-[8px] font-poppins-semibold text-white rounded-[20px] ${
              isSuperAdmin ? 'opacity-100' : 'opacity-50 cursor-not-allowed'
            }`}
          >
            Add Admin
          </button>
        </div>
        {adminList && adminList.length ? (
          <div>
            <StyledTableContainer>
              <StyledTable>
                <TableHead>
                  <TableRow>
                    <StyledTableCell
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      Role{' '}
                      <button
                        className="ml-[4px] flex justify-center"
                        onClick={() => setOpenAdminRole(true)}
                      >
                        <ion-icon
                          name="information-circle-outline"
                          style={{ width: '16px', height: '16px' }}
                        ></ion-icon>
                      </button>
                    </StyledTableCell>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell>Phone</StyledTableCell>
                    <StyledTableCell>Email</StyledTableCell>
                    <StyledTableCell sx={{ textAlign: 'center' }}>
                      Action
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {adminList.map((admin) => (
                    <TableRow key={admin.email}>
                      <StyledTableCell>{admin.role}</StyledTableCell>
                      <StyledTableCell>{admin.name}</StyledTableCell>
                      <StyledTableCell>{admin.phone_number}</StyledTableCell>
                      <StyledTableCell>{admin.email}</StyledTableCell>
                      <StyledTableCell sx={{ textAlign: 'center' }}>
                        <button
                          onClick={() => goToAdminDetail(admin._id)}
                          className={`font-poppins-semibold py-[4px] px-[16px] border rounded-[20px] border-black-100 ${
                            (userInfo && userInfo._id === admin._id) ||
                            isSuperAdmin
                              ? 'opacity-100'
                              : 'opacity-50 cursor-not-allowed'
                          }`}
                        >
                          Detail
                        </button>
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </StyledTable>
            </StyledTableContainer>
            <Pagination
              pageData={pageData}
              totalPages={totalPages}
              onChange={handlePageChange}
            />
          </div>
        ) : null}
      </LayoutAdmin>
    </>
  );
};

export default AdminPage;
