import { useForm } from 'react-hook-form';
import LCAmount from './LCAmount';
import { yupResolver } from '@hookform/resolvers/yup';
import { sendLC } from '../../utils/validationForm';
import Input from '../Input/Input';
import ActionButton from '../Button/ActionButton';
import SecondaryButton from '../Button/SecondaryButton';
import { useEffect, useState } from 'react';
import ConfirmTNC from './ConfirmTNC';
import { useDispatch, useSelector } from 'react-redux';
import { checkReceiverName, sendTransferOTP } from '../../actions/wallet';
import getToastMessage from '../../utils/toastMessage';
import { enqueueSnackbar } from 'notistack';
import { setLCData } from '../../slices/walletSlice';

const SendLC = ({ setOtpModal, playerEmail }) => {
  const {
    register,
    handleSubmit,
    formState,
    watch,
    setValue,
    getFieldState,
    getValues,
  } = useForm({
    resolver: yupResolver(sendLC),
  });
  const { adminBalance } = useSelector((state) => state.dashboard);
  const dispatch = useDispatch();
  const watchAmount = watch('cku_amount', 0);
  const [agreedTNC, setAgreedTNC] = useState(false);
  const [verifiedEmail, setVerifiedEmail] = useState(false);

  const onSubmit = async (data) => {
    if (agreedTNC && verifiedEmail) {
      if (watchAmount > adminBalance.cku_balance) {
        enqueueSnackbar('You do not have enough lucky coin!', {
          variant: 'warning',
        });
        return;
      }
      const done = await dispatch(sendTransferOTP(data));
      if (done) {
        const { message, variant } = getToastMessage(done);
        if (variant === 'success') {
          dispatch(setLCData({ type: 'send', data }));
          setOtpModal('send');
          enqueueSnackbar(message, { variant });
        }
      }
    } else if (!agreedTNC) {
      enqueueSnackbar('Please read and check the Terms and Conditions', {
        variant: 'warning',
      });
    } else if (!verifiedEmail) {
      enqueueSnackbar('Please verify the email address', {
        variant: 'warning',
      });
    }
  };

  const checkEmail = async () => {
    if (getFieldState('email').invalid) {
      enqueueSnackbar('Please provide a valid email', { variant: 'warning' });
    } else {
      const done = await dispatch(
        checkReceiverName({ email: getValues('email') })
      );
      if (done) {
        const { message, variant } = getToastMessage(done);
        if (message && variant === 'success') {
          setVerifiedEmail(true);
          enqueueSnackbar(message, { variant });
        } else {
          setVerifiedEmail(false);
        }
      }
    }
  };

  const resetEmail = () => {
    setValue('email', '');
    setVerifiedEmail(false);
  };

  useEffect(() => {
    if (playerEmail) {
      setValue('email', playerEmail);
      setVerifiedEmail(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerEmail]);

  return (
    <>
      <LCAmount />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-row w-full justify-between items-end mb-[18px]">
          <Input
            title="CKU Amount"
            name="cku_amount"
            placeholder="CKU"
            classes="w-[45%] sm:w-[55%] !mb-0"
            register={register}
            errors={formState.errors}
          />
          <div>
            <SecondaryButton
              title="5%"
              classes="!w-fit h-fit ml-[4px] !px-[12px] !py-[4px]"
              onClick={() => {
                if (adminBalance) {
                  setValue('cku_amount', 0.05 * adminBalance.cku_balance);
                }
              }}
            />
            <SecondaryButton
              title="10%"
              classes="!w-fit h-fit mx-[4px] !px-[12px] !py-[4px]"
              onClick={() => {
                if (adminBalance) {
                  setValue('cku_amount', 0.1 * adminBalance.cku_balance);
                }
              }}
            />
          </div>
        </div>
        <div className="flex flex-row w-full items-end mb-[18px]">
          <div className="relative w-[80%]">
            <Input
              title="Duotrx’s Trader Email Address Receiver"
              name="email"
              placeholder="Input trader's email"
              classes=" !mb-0"
              register={register}
              errors={formState.errors}
              isDisabled={verifiedEmail}
            />
            {verifiedEmail ? (
              <ion-icon
                name="shield-checkmark"
                style={{
                  position: 'absolute',
                  height: '14px',
                  width: '14px',
                  bottom: '12px',
                  right: '8px',
                  color: '#27AE60',
                }}
              ></ion-icon>
            ) : null}
          </div>
          <SecondaryButton
            title={verifiedEmail ? 'Remove' : 'Find'}
            classes="!w-fit h-fit mx-[4px] !px-[12px] !py-[4px]"
            onClick={() => (verifiedEmail ? resetEmail() : checkEmail())}
          />
        </div>
        <ConfirmTNC TNCState={agreedTNC} setTNCState={setAgreedTNC} />
        <ActionButton
          title={`Send ${watchAmount ? `${watchAmount} CKU` : ''}`}
          type="submit"
          classes="mb-[4px]"
        />
      </form>
    </>
  );
};

export default SendLC;
