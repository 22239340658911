import Modal from './Modal';

const AdminModalInfo = ({ closeModal }) => {
  return (
    <Modal title="Admin Information" closeModal={closeModal}>
      <h3 className="font-poppins-bold text-sm">1. Role</h3>
      <ul className="ml-[40px] list-[lower-alpha] text-sm">
        <li>
          <p className="font-poppins-bold">Super Admin:</p>
          <ul className="list-[lower-roman] ml-[20px]">
            <li>
              Super Admin can see, add, edit and remove another admin
              <ul className="list-decimal ml-[20px]">
                <li>Super admin can see and manage Temporary password</li>
              </ul>
            </li>
            <li>Super Admin can access balance system via MoonPay.</li>
            <li>Full access all pages.</li>
          </ul>
        </li>
        <li>
          <p className="font-poppins-bold">Admin:</p>
          <ul className="list-[lower-roman] ml-[20px]">
            <li>Admin can only access his/her detail.</li>
            <li>
              Admin only has access to:
              <ul className="list-decimal ml-[20px]">
                <li>Admin list</li>
                <li>Dashboard</li>
                <li>Exchange History List</li>
                <li>Lottery Management</li>
                <li>Customer (Player)</li>
                <li>Web Management</li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </Modal>
  );
};

export default AdminModalInfo;
