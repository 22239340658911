import { serverAPI } from './API';

const convToBase64 = (img) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(img);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

const convertBase64ToFile = (base64, name) => {
  var arr = base64.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  var convertedFile = new File([u8arr], getFileName(name, mime.split('/')[1]), {
    type: mime,
  });
  return convertedFile;
};

const getFileName = (name = 'img', extension = 'jpg') => {
  var d = new Date().getDate(),
    m = new Date().getMonth(),
    y = new Date().getFullYear(),
    t = new Date().getTime();
  return `${name}-${m + d + y + t}.${extension}`.toLowerCase();
};

const convertImgFileToLink = (file, name, type) => {
  return new Promise((resolve, reject) => {
    if (file) {
      convToBase64(file).then((res) => {
        const image = convertBase64ToFile(res, name);
        const extension = image.name.split('.');
        const formattedFilename = getFileName(
          name,
          extension[extension.length - 1]
        );

        const formdata = new FormData();
        formdata.append('file', image, formattedFilename);
        formdata.append('imagename', formattedFilename);
        formdata.append('image_type', type);

        serverAPI
          .post('adminImageUpload', formdata)
          .then((res) => {
            if (res) {
              const { data } = res;
              if (data && data.path) resolve(data.path);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  });
};

export { convToBase64, convertImgFileToLink };
