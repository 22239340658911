import { Controller } from 'react-hook-form';
import { useRef } from 'react';
import StyledSwitch from '../Button/StyledSwitch';
import { useDispatch } from 'react-redux';
import { setIsLoading } from '../../slices/binarySlice';
import { convertImgFileToLink } from '../../utils/convToBase64';
import { enqueueSnackbar } from 'notistack';
import Input from '../Input/Input';

const PopupBannerForm = ({ register, control, errors }) => {
  const dispatch = useDispatch();
  const uploadBannerRef = useRef();

  return (
    <div className="flex flex-col">
      <div className="flex flex-row items-center gap-[10px] my-[16px]">
        <h3 className="text-green font-poppins-semibold text-base">
          Promotional/Pop up Banner (Optional)
        </h3>
        <Controller
          control={control}
          name="use_banner"
          render={({ field: { value, onChange } }) => {
            return (
              <StyledSwitch
                onChange={() => {
                  onChange(!value);
                }}
                checked={value ? value : false}
              />
            );
          }}
        />
      </div>
      <Controller
        control={control}
        name="banner_image"
        render={({ field: { value, onChange } }) => {
          return (
            <div className="flex flex-col mb-[18px]">
              <input
                ref={uploadBannerRef}
                type="file"
                accept="image/*"
                className="hidden"
                onChange={(event) => {
                  const file = event.target.files[0];
                  if (file) {
                    dispatch(setIsLoading(true));
                    convertImgFileToLink(file, 'coin-banner-image', 'banner')
                      .then((res) => {
                        onChange(res);
                      })
                      .catch((error) => {
                        if (error.response && error.response.data.message) {
                          enqueueSnackbar(error.response.data.message, {
                            variant: 'error',
                          });
                        } else {
                          enqueueSnackbar(error.message, {
                            variant: 'error',
                          });
                        }
                      })
                      .finally(() => dispatch(setIsLoading(false)));
                  }
                }}
              />
              <div
                className={`relative flex flex-col w-full h-[70px] rounded-lg cursor-pointer ${
                  value ? 'bg-transparent' : 'bg-black-100'
                }`}
              >
                {value ? (
                  <>
                    <button
                      type="button"
                      className="flex absolute top-[4px] right-[4px] bg-white z-10"
                      onClick={() => onChange('')}
                    >
                      <ion-icon
                        name="trash-outline"
                        style={{
                          width: '20px',
                          height: '20px',
                        }}
                      ></ion-icon>
                    </button>
                    <img
                      src={value}
                      alt="banner-coin"
                      onClick={() => {
                        uploadBannerRef.current.click();
                      }}
                      className="w-full h-full object-cover rounded-lg"
                    />
                  </>
                ) : (
                  <button
                    type="button"
                    className="bg-white m-auto rounded-[20px] text-sm px-[16px] py-[8px]"
                    onClick={() => {
                      uploadBannerRef.current.click();
                    }}
                  >
                    Upload Image
                  </button>
                )}
              </div>
              {errors && errors.banner_image && (
                <p className="text-xs text-red">
                  {errors.banner_image.message}
                </p>
              )}
            </div>
          );
        }}
      />
      <Controller
        control={control}
        name="banner_message"
        render={({ field }) => (
          <div className="flex flex-col mb-[18px]">
            <label className="text-sm">Message</label>
            <textarea
              {...field}
              onChange={field.onChange}
              placeholder="Message"
              className="p-[8px] border bg-white border-black-100 outline-none text-sm rounded-md w-full min-h-[80px]"
            />
            {errors && errors.banner_message ? (
              <p className="text-xs text-red">
                {errors.banner_message.message}
              </p>
            ) : null}
          </div>
        )}
      />
      <Input
        name="button_name"
        register={register}
        title="Button Name"
        placeholder="Visit ..."
        errors={errors}
      />
      <Input
        name="button_link"
        register={register}
        title="Button Link"
        placeholder="https:"
        errors={errors}
      />
    </div>
  );
};

export default PopupBannerForm;
