import { createSlice } from '@reduxjs/toolkit';
import {
  deleteAdmin,
  editAdmin,
  fetchAdminInfo,
  fetchAdminList,
  registerAdmin,
} from '../actions/admin';

const initialState = {
  adminList: [],
  newAdmins: [],
  selectedAdminInfo: null,
  isLoading: false,
  totalPages: 0,
  pageData: {
    page: 0,
    search: '',
    dataLimit: 10,
  },
};

export const admin = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    setSelectedAdminInfo: (state, action) => {
      state.selectedAdminInfo = action.payload;
    },
    setNewAdmins: (state, action) => {
      state.newAdmins = action.payload;
    },
    setPageData: (state, action) => {
      state.pageData = { ...state.pageData, ...action.payload };
    },
  },
  extraReducers: {
    [fetchAdminList.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchAdminList.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.adminList = payload.admin_list;
      state.totalPages = payload.pages;
    },
    [fetchAdminList.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [fetchAdminInfo.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchAdminInfo.rejected]: (state) => {
      state.isLoading = false;
    },
    [fetchAdminInfo.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      if (state.newAdmins && state.newAdmins.length) {
        const isNewAdmin = state.newAdmins.filter(
          (admin) => admin._id === payload._id
        );
        if (isNewAdmin.length) {
          state.newAdmins = state.newAdmins.filter((admin) => {
            return admin._id !== isNewAdmin[0]._id;
          });
          payload.password = isNewAdmin[0].password;
        }
      }
      state.selectedAdminInfo = { ...payload };
    },
    [registerAdmin.pending]: (state) => {
      state.isLoading = true;
    },
    [registerAdmin.rejected]: (state) => {
      state.isLoading = false;
    },
    [registerAdmin.fulfilled]: (state, { payload }) => {
      const { details } = payload;
      state.isLoading = false;
      if (state.pageData.page === 0) {
        state.adminList = [details, ...state.adminList];
      }
      if (!state.newAdmins || !state.newAdmins.length) {
        state.newAdmins = [{ _id: details._id, password: details.password }];
      } else if (state.newAdmins && state.newAdmins.length) {
        state.newAdmins = [
          ...state.newAdmins,
          { _id: details._id, password: details.password },
        ];
      }
    },
    [editAdmin.pending]: (state) => {
      state.isLoading = true;
    },
    [editAdmin.rejected]: (state) => {
      state.isLoading = false;
    },
    [editAdmin.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      const { details } = payload;
      if (!state.newAdmins || !state.newAdmins.length) {
        state.newAdmins = [{ _id: details._id, password: details.password }];
      } else if (state.newAdmins && state.newAdmins.length) {
        state.newAdmins = [
          ...state.newAdmins,
          { _id: details._id, password: details.password },
        ];
      }
      state.selectedAdminInfo = details;
    },
    [deleteAdmin.pending]: (state) => {
      state.isLoading = true;
    },
    [deleteAdmin.rejected]: (state) => {
      state.isLoading = false;
    },
    [deleteAdmin.fulfilled]: (state, { payload }) => {
      console.log(payload, '===paylod delete admin');
      state.adminList = state.adminList.filter(
        (admin) => admin._id !== payload.id
      );
      state.isLoading = false;
    },
  },
});

export const { setSelectedAdminInfo, setNewAdmins, setPageData } =
  admin.actions;

export default admin.reducer;
