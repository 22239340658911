import { createSlice } from '@reduxjs/toolkit';
import {
  adminLogin,
  forgotPasswordStep1,
  forgotPasswordStep2,
  forgotPasswordStep3,
} from '../actions/auth';

const initialState = {
  userInfo: null,
  isLoading: false,
};

export const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
  },
  extraReducers: {
    [adminLogin.pending]: (state) => {
      state.isLoading = true;
    },
    [adminLogin.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.userInfo = payload;
    },
    [adminLogin.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [forgotPasswordStep1.pending]: (state) => {
      state.isLoading = true;
    },
    [forgotPasswordStep1.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
    },
    [forgotPasswordStep1.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [forgotPasswordStep2.pending]: (state) => {
      state.isLoading = true;
    },
    [forgotPasswordStep2.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
    },
    [forgotPasswordStep2.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [forgotPasswordStep3.pending]: (state) => {
      state.isLoading = true;
    },
    [forgotPasswordStep3.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      if (payload.user) {
        state.userInfo = payload.user;
        window.location.replace(`/`);
      }
    },
    [forgotPasswordStep3.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUserInfo } = auth.actions;

export default auth.reducer;
