import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';
import { adminLogin } from '../actions/auth';
import Logo from '../assets/imgs/duotrx-logo.png';
import Input from '../components/Input/Input';
import { loginAdmin } from '../utils/validationForm';
import { useIsLoggedIn } from '../hooks/useAuth';
import ForgotPasswordModal from '../components/Modal/ForgotPasswordModal';
import Loading from '../components/Common/Loading';

const LoginPage = () => {
  const { isLoading, userInfo } = useSelector((state) => state.auth);
  const isLoggedIn = useIsLoggedIn();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showForgotEmail, setShowForgotEmail] = useState(false);
  const { register, handleSubmit, reset, formState } = useForm({
    resolver: yupResolver(loginAdmin),
  });
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = (data) => {
    if (!isLoading) dispatch(adminLogin(data));
  };

  useEffect(() => {
    if (userInfo || isLoggedIn) {
      if (userInfo && userInfo.role && userInfo.name) {
        enqueueSnackbar(`${userInfo.role} ${userInfo.name} has logged in`, {
          variant: 'success',
        });
      }
      document.activeElement.blur();
      reset();
      setTimeout(() => {
        navigate('/');
      }, 200);
    }
  }, [navigate, reset, userInfo, isLoggedIn, enqueueSnackbar]);

  useEffect(() => {
    document.body.style.backgroundColor = '#fff';
  }, []);

  return (
    <>
      {isLoading ? <Loading /> : null}
      {showForgotEmail ? (
        <ForgotPasswordModal closeModal={setShowForgotEmail} />
      ) : null}
      <div className="flex bg-white-f flex-col justify-center items-center w-[80%] sm:w-[30%] m-auto h-[100vh]">
        <div className="h-[140px] sm:h-[200px] w-full">
          <img
            src={Logo}
            alt="lucky cat logo"
            className="w-full h-full object-contain ml-[-24px]"
          />
        </div>
        <h4 className="mt-[16px] font-poppins-bold text-[20px]">
          Duotrx Admin
        </h4>
        <p className="text-sm mb-[16px]">Admin Login</p>
        <form onSubmit={handleSubmit(onSubmit)} className="w-full mx-auto">
          <Input
            title="Admin Email"
            placeholder="Admin Email"
            register={register}
            name="email"
            errors={formState.errors}
          />
          <Input
            title="Password"
            placeholder="Password"
            type="password"
            name="password"
            register={register}
            errors={formState.errors}
          />

          <p className="text-sm font-poppins-semibold mt-[20px]">
            Forgot Password?{' '}
            <span
              className="underline cursor-pointer"
              onClick={() => setShowForgotEmail(true)}
            >
              Send Link
            </span>
          </p>
          <input type="submit" className="invisible absolute" />
          <button
            className="bg-black-100 text-white text-base font-poppins-medium w-full py-[8px] rounded-[20px] mt-[12px]"
            type="submit"
          >
            Login
          </button>
        </form>
      </div>
    </>
  );
};

export default LoginPage;
