import { useDispatch, useSelector } from 'react-redux';
import { resetPageData, setTicketPageData } from '../../slices/playersSlice';
import StyledTable from '../Table/StyledTable';
import StyledTableContainer from '../Table/StyledTableContainer';
import StyledTableCell from '../Table/StyledTableCell';
import { TableBody, TableHead, TableRow } from '@mui/material';
import Pagination from '../Table/Pagination';
import { useEffect } from 'react';
import DataLimit from '../Table/DataLimit';
import moment from 'moment';

const PlayerTicketHistory = () => {
  const { ticketPageData, selectedPlayerTicketHistory, ticketPages } =
    useSelector((state) => state.players);
  const dispatch = useDispatch();
  const onSearchTicket = (ev) => {
    if (ev.key === 'Enter' && ev.target.value !== ticketPageData.search) {
      const search = ev.target.value;
      dispatch(setTicketPageData({ search }));
    }
  };

  const handlePageChange = (ev) => {
    if (ev.selected !== ticketPageData.page) {
      dispatch(setTicketPageData({ page: ev.selected }));
    }
  };

  useEffect(() => {
    return () => {
      dispatch(resetPageData('ticketPageData'));
    };
  }, [dispatch]);

  return (
    <>
      <div className="flex flex-col">
        <h3 className="text-base font-poppins-semibold">Trading History</h3>
        <div className="flex flex-col my-[16px]">
          <label className="text-sm ">Search</label>
          <input
            placeholder="Currency, Transaction ID, Trade Group"
            onKeyDown={onSearchTicket}
            className="bg-white w-full lg:w-1/3 p-[8px] border border-black-100 rounded-md text-sm outline-none"
          />
        </div>
        {selectedPlayerTicketHistory && selectedPlayerTicketHistory.length ? (
          <div>
            <DataLimit
              initialLimit={ticketPageData.dataLimit}
              setter={setTicketPageData}
              classList="mb-[16px]"
            />
            <StyledTableContainer>
              <StyledTable>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Currency/USD</StyledTableCell>
                    <StyledTableCell>Transaction ID</StyledTableCell>
                    <StyledTableCell>Trade Time Start</StyledTableCell>
                    <StyledTableCell>Trade Time End</StyledTableCell>
                    <StyledTableCell>Trade Group</StyledTableCell>
                    <StyledTableCell>Market</StyledTableCell>
                    <StyledTableCell>Trade Status</StyledTableCell>
                    <StyledTableCell>CKU Amount</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedPlayerTicketHistory.map((ticket) => (
                    <TableRow key={ticket._id}>
                      <StyledTableCell>
                        {ticket.coin_info.currency}
                      </StyledTableCell>
                      <StyledTableCell>{ticket.transaction_id}</StyledTableCell>
                      <StyledTableCell>
                        {moment(ticket.createdAt).format('DD-MM-YYYY, HH:mm')}
                      </StyledTableCell>
                      <StyledTableCell>
                        {ticket.market_info && ticket.market_info.endDate
                          ? moment(ticket.market_info.endDate).format(
                              'DD-MM-YYYY, HH:mm'
                            )
                          : 'TBD'}
                      </StyledTableCell>
                      <StyledTableCell>
                        {ticket.trade_group ? ticket.trade_group.name : ''}
                      </StyledTableCell>
                      <StyledTableCell>{ticket.trade_type}</StyledTableCell>
                      <StyledTableCell>
                        {Math.sign(ticket.trade_result) !== -1 ? '+' : ''}
                        {ticket.trade_result}
                      </StyledTableCell>
                      <StyledTableCell>{ticket.trade_amount}</StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </StyledTable>
            </StyledTableContainer>
            <Pagination
              totalPages={ticketPages}
              pageData={ticketPageData}
              onChange={handlePageChange}
            />
          </div>
        ) : (
          <p className="text-sm">No trading data found!</p>
        )}
      </div>
    </>
  );
};

export default PlayerTicketHistory;
