import { useEffect, useState } from 'react';
import LayoutAdmin from '../components/Layout/LayoutAdmin';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTicketList } from '../actions/ticket';
import Loading from '../components/Common/Loading';
import { setPageData } from '../slices/ticketSlice';
import StyledTable from '../components/Table/StyledTable';
import StyledTableContainer from '../components/Table/StyledTableContainer';
import StyledTableCell from '../components/Table/StyledTableCell';
import { TableBody, TableHead, TableRow } from '@mui/material';
import Pagination from '../components/Table/Pagination';
import DateRangeModal from '../components/Modal/DateRangeModal';
import StatusFilterModal from '../components/Modal/StatusFilterModal';
import SeasonFilterModal from '../components/Modal/SeasonFilterModal';
import moment from 'moment';
import DataLimit from '../components/Table/DataLimit';
import { thousandSeparator } from '../utils/math';

const TicketsPage = () => {
  const { isLoading, ticketList, pageData, totalPages } = useSelector(
    (state) => state.ticket
  );
  const [modalSection, setModalSection] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTicketList(pageData));
  }, [dispatch, pageData]);

  const onSearchTicket = (ev) => {
    if (ev.key === 'Enter' && ev.target.value !== pageData.search) {
      const search = ev.target.value;
      dispatch(setPageData({ search }));
    }
  };

  const handlePageChange = (ev) => {
    if (ev.selected !== pageData.page) {
      dispatch(setPageData({ page: ev.selected }));
    }
  };

  return (
    <>
      {isLoading ? <Loading /> : null}
      {modalSection === 'date_range' ? (
        <DateRangeModal
          selector="ticket"
          setter={setPageData}
          stateName="pageData"
          closeModal={setModalSection}
        />
      ) : modalSection === 'status_filter' ? (
        <StatusFilterModal closeModal={setModalSection} />
      ) : modalSection === 'season_filter' ? (
        <SeasonFilterModal closeModal={setModalSection} />
      ) : null}
      <LayoutAdmin pageSubtitle="Transactions" pageTitle="Transactions">
        <div className="flex flex-col mb-[24px]">
          <label className="text-sm ">Search</label>
          <input
            placeholder="Type, Sender Receiver Address"
            onKeyDown={onSearchTicket}
            className="bg-white w-full lg:w-1/3 p-[8px] border border-black-100 rounded-md text-sm outline-none"
          />
        </div>
        <DataLimit
          initialLimit={pageData.dataLimit}
          setter={setPageData}
          classList="mb-[16px]"
        />
        <div className="mt-[12px]">
          {/* <div className="flex flex-row gap-[8px] overflow-auto max-w-none w-full mb-[8px] pb-[8px]">
            <button
              type="button"
              onClick={() => setModalSection('status_filter')}
              className="min-w-max bg-black-100 text-white rounded-[20px] px-[16px] py-[8px] font-poppins-semibold"
            >
              Status Filter
            </button>
            <button
              type="button"
              onClick={() => setModalSection('date_range')}
              className="min-w-max bg-black-100 text-white rounded-[20px] px-[16px] py-[8px] font-poppins-semibold"
            >
              Date Filter
            </button>
          </div> */}
          {ticketList && ticketList.length && !isLoading ? (
            <div className="flex flex-col">
              <StyledTableContainer>
                <StyledTable>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Transaction Date</StyledTableCell>
                      <StyledTableCell>Type</StyledTableCell>
                      <StyledTableCell>Amount</StyledTableCell>
                      <StyledTableCell>Currency/USD</StyledTableCell>
                      <StyledTableCell>Market</StyledTableCell>
                      <StyledTableCell>Receiver</StyledTableCell>
                      <StyledTableCell>Sender</StyledTableCell>
                      <StyledTableCell>Receiver Wallet Address</StyledTableCell>
                      <StyledTableCell>Sender Wallet Address</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {ticketList.map((trs) => (
                      <TableRow key={trs._id}>
                        <StyledTableCell>
                          {moment(trs.createdAt).format('DD-MM-YYYY, HH:mm:ss')}
                        </StyledTableCell>
                        <StyledTableCell>
                          {trs.transaction_type}
                        </StyledTableCell>
                        <StyledTableCell>
                          CKU {thousandSeparator(trs.total_payment)}
                        </StyledTableCell>
                        <StyledTableCell>
                          {trs.market_info && trs.market_info.currency
                            ? trs.market_info.currency
                            : '-'}
                        </StyledTableCell>
                        <StyledTableCell>
                          {trs.market_info && trs.market_info.trade_type
                            ? trs.market_info.trade_type
                            : '-'}
                        </StyledTableCell>
                        <StyledTableCell>
                          {trs.sender_receiver &&
                          trs.sender_receiver.receiver &&
                          trs.sender_receiver.receiver.name
                            ? trs.sender_receiver.receiver.name
                            : '-'}
                        </StyledTableCell>
                        <StyledTableCell>
                          {trs.sender_receiver &&
                          trs.sender_receiver.sender &&
                          trs.sender_receiver.sender.name
                            ? trs.sender_receiver.sender.name
                            : '-'}
                        </StyledTableCell>
                        <StyledTableCell>
                          {trs.sender_receiver &&
                          trs.sender_receiver.receiver &&
                          trs.sender_receiver.receiver.wallet_address
                            ? trs.sender_receiver.receiver.wallet_address
                            : '-'}
                        </StyledTableCell>
                        <StyledTableCell>
                          {trs.sender_receiver &&
                          trs.sender_receiver.sender &&
                          trs.sender_receiver.sender.wallet_address
                            ? trs.sender_receiver.sender.wallet_address
                            : '-'}
                        </StyledTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </StyledTable>
              </StyledTableContainer>
              <Pagination
                totalPages={totalPages}
                pageData={pageData}
                onChange={handlePageChange}
              />
            </div>
          ) : (
            <h3 className="text-center mt-[5rem] text-base">
              No transactions found
            </h3>
          )}
        </div>
      </LayoutAdmin>
    </>
  );
};

export default TicketsPage;
