import { useDispatch, useSelector } from 'react-redux';
import { editOptionStatus } from '../../actions/binary';
import getToastMessage from '../../utils/toastMessage';
import { enqueueSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import moment from 'moment';

const CoinActivationStatus = () => {
  const { selectedOptionDetail } = useSelector((state) => state.binary);
  const dispatch = useDispatch();
  const { id } = useParams();

  const handleStatusChange = async (ev) => {
    const optionData = {
      binary_option_id: selectedOptionDetail._id,
      status: ev.target.value,
    };
    const done = await dispatch(editOptionStatus(optionData));
    if (done) {
      const { message, variant } = getToastMessage(done);
      if (variant === 'success') enqueueSnackbar(message, { variant });
    }
  };

  if (selectedOptionDetail) {
    return (
      <div className="flex flex-col border-b border-b-[#EBEAEA] pb-[8px]">
        <h3 className="text-green font-poppins-semibold text-base">
          Activation Status
        </h3>
        <div className="relative flex flex-row w-full justify-between items-center text-sm pb-[8px]">
          <label className=" w-1/2 font-poppins-semibold">
            Activate currency
          </label>
          <select
            defaultValue={
              selectedOptionDetail.status
                ? selectedOptionDetail.status
                : 'Hidden'
            }
            onChange={handleStatusChange}
            disabled={id === 'new'}
            className="appearance-none bg-white py-[11px] px-[8px] border border-black-100 rounded-[6px] outline-none w-1/2"
          >
            <option value="Hidden">Hidden</option>
            <option value="Active">Active</option>
          </select>
          <span className="absolute right-[8px] top-[11px]">
            <ion-icon name="chevron-down-outline"></ion-icon>
          </span>
        </div>
        <div className="flex flex-row w-full justify-between items-center text-sm mb-[8px]">
          <label className="w-1/2 font-poppins-semibold">Last update</label>
          <p className="w-1/2">
            {selectedOptionDetail.updatedAt
              ? moment(selectedOptionDetail.updatedAt).format(
                  'DD-MM-YYYY, HH:mm:ss'
                )
              : '-'}
          </p>
        </div>
        <div className="flex flex-row w-full justify-between items-center text-sm mb-[8px]">
          <label className="w-1/2 font-poppins-semibold">Updated by</label>
          <p className="w-1/2">
            {selectedOptionDetail.updated_by
              ? selectedOptionDetail.updated_by
              : '-'}
          </p>
        </div>
      </div>
    );
  }

  return null;
};

export default CoinActivationStatus;
