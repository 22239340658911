import { Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './store';
import LoginPage from './pages/Login';
import HomePage from './pages/Home';
import AdminPage from './pages/Admin';
import AdminDetailPage from './pages/AdminDetail';
import LotteryPage from './pages/Lottery';
import PrivateRoutes from './components/Route/PrivateRoutes';
import ContactPage from './pages/Contact';
import ContentPage from './pages/Content';
import TicketsPage from './pages/Tickets';
import PlayersPage from './pages/Players';
import RecruitersPage from './pages/Recruiters';
import PlayerDetailPage from './pages/PlayerDetail';
import ChangePasswordPage from './pages/ChangePassword';
import TicketDetailPage from './pages/TicketDetail';
import RecruiterDetailPage from './pages/RecruiterDetail';
import { useEffect } from 'react';
import { getUSDPrice } from './actions/dashboard';
import BinaryDetailPage from './pages/BinaryDetail';

function App() {
  useEffect(() => {
    const admin_token = localStorage.getItem('USER_KEY');
    if (admin_token) store.dispatch(getUSDPrice());
  }, []);

  return (
    <Provider store={store}>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/change-password" element={<ChangePasswordPage />} />
        <Route element={<PrivateRoutes />}>
          <Route path="/" element={<HomePage />} />
          <Route path="/admins" element={<AdminPage />} />
          <Route path="/admin/:id" element={<AdminDetailPage />} />
          <Route path="/option" element={<LotteryPage />} />
          <Route path="/option/:id" element={<BinaryDetailPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/content" element={<ContentPage />} />
          <Route path="/transactions" element={<TicketsPage />} />
          <Route path="/transaction/:id" element={<TicketDetailPage />} />
          <Route path="/traders" element={<PlayersPage />} />
          <Route path="/traders/:id" element={<PlayerDetailPage />} />
          <Route path="/recruiters" element={<RecruitersPage />} />
          <Route path="/recruiter/:id" element={<RecruiterDetailPage />} />
        </Route>
      </Routes>
    </Provider>
  );
}

export default App;
