export const thousandSeparator = (value) => {
  if (!value) return 0;
  value = value % 1 !== 0 ? truncateDecimals(value, 2) : value;
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const truncateDecimals = (number, decimalPlaces) => {
  // Multiply by 10^decimalPlaces to shift the decimal places to the right
  // Use Math.floor() to truncate the decimal part
  // Divide by 10^decimalPlaces to shift the decimal places back to the original position
  return (
    Math.floor(number * Math.pow(10, decimalPlaces)) /
    Math.pow(10, decimalPlaces)
  );
};

export const formatNumberWithSuffix = (number) => {
  if (number >= 1000000) {
    return +(number / 1000000).toFixed(1).toString() + 'm';
  } else if (number >= 1000) {
    return +(number / 1000).toFixed(1).toString() + 'K';
  } else {
    return number.toString();
  }
};

export const areAllUnique = (arr) => {
  for (let i = 0; i < arr.length; i++) {
    if (arr.indexOf(arr[i]) !== arr.lastIndexOf(arr[i])) {
      return false;
    }
  }
  return true;
};
