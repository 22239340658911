import { createAsyncThunk } from '@reduxjs/toolkit';
import { serverAPI } from '../utils/API';
import { enqueueSnackbar } from 'notistack';

export const fetchDrawdownList = createAsyncThunk(
  'drawdown/drawdownList',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.get('adminDrawdownList', {
        params,
      });
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const fetchDrawdownInfo = createAsyncThunk(
  'drawdown/drawdownInfo',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.get('adminDrawdownDetail', {
        params,
      });
      return data.drawdown;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        if (error.response.data.message !== 'Drawdown not found') {
          enqueueSnackbar(error.response.data.message, { variant: 'error' });
        }
        return rejectWithValue(error.response.data.message);
      } else {
        if (error.message !== 'Drawdown not found') {
          enqueueSnackbar(error.message, { variant: 'error' });
        }
        return rejectWithValue(error.message);
      }
    }
  }
);

export const createDrawdown = createAsyncThunk(
  'drawdown/createDrawdown',
  async (drawdownData, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.post('createDrawdown', drawdownData);
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const createNextDrawdown = createAsyncThunk(
  'drawdown/createNextDrawdown',
  async (drawdownData, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.post('createNextDrawdown', drawdownData);
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const resumePausePrize = createAsyncThunk(
  'drawdown/resumePausePrize',
  async (drawdownData, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.put('resumePausePrize', drawdownData);
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const drawdownTopUpPrize = createAsyncThunk(
  'drawdown/topUpPrize',
  async (drawdownData, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.put('topUpPrize', drawdownData);
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const editPrizeDistribution = createAsyncThunk(
  'drawdown/editPrizeDistribution',
  async (drawdownData, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.put(
        'editPrizeDistribution',
        drawdownData
      );
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const createUpdateDrawdate = createAsyncThunk(
  'drawdown/createUpdateDrawdate',
  async (drawdownData, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.put(
        'createUpdateDrawdate',
        drawdownData
      );
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const createUpdateFreezeDate = createAsyncThunk(
  'drawdown/createUpdateFreezeDate',
  async (drawdownData, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.put(
        'createUpdatePurchaseFreezeTime',
        drawdownData
      );
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const createUpdateAnnouncement = createAsyncThunk(
  'drawdown/createUpdateAnnouncement',
  async (drawdownData, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.put(
        'createUpdateAnnouncement',
        drawdownData
      );
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const addWinningNumbers = createAsyncThunk(
  'drawdown/addWinningNumbers',
  async (drawdownData, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.put('addWinningNumbers', drawdownData);
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);
