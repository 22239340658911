import Modal from '../Modal/Modal';

const WinningPotInfo = ({ closeModal }) => {
  return (
    <Modal title="Winning Pot" closeModal={closeModal}>
      <ul className="text-sm ml-[20px] list-decimal">
        <li>
          Comes from 60% of the LC raised from the lottery sales (total
          transaction) that set aside.
        </li>
      </ul>
    </Modal>
  );
};

export default WinningPotInfo;
