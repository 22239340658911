import { createAsyncThunk } from '@reduxjs/toolkit';
import { serverAPI } from '../utils/API';
import { enqueueSnackbar } from 'notistack';

export const fetchCoinList = createAsyncThunk(
  'binary/coinList',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.get('bitstampCurrencyList', {
        params,
      });
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const fetchOptionList = createAsyncThunk(
  'binary/optionList',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.get('adminBinaryOptionList', {
        params,
      });
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const fetchOptionDetail = createAsyncThunk(
  'binary/optionDetail',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.get('adminBinaryOptionDetail', {
        params,
      });
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        if (error.response.data.message !== 'Drawdown not found') {
          enqueueSnackbar(error.response.data.message, { variant: 'error' });
        }
        return rejectWithValue(error.response.data.message);
      } else {
        if (error.message !== 'Drawdown not found') {
          enqueueSnackbar(error.message, { variant: 'error' });
        }
        return rejectWithValue(error.message);
      }
    }
  }
);

export const createOption = createAsyncThunk(
  'binary/createOption',
  async (optionData, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.post('createBinaryOption', optionData);
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const editOption = createAsyncThunk(
  'binary/editOption',
  async (optionData, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.put(
        'editBinaryOptionSettings',
        optionData
      );
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const editOptionStatus = createAsyncThunk(
  'binary/editOptionStatus',
  async (optionData, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.put(
        'editBinaryOptionStatus',
        optionData
      );
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const createUpdateTradeGroup = createAsyncThunk(
  'binary/createUpdateTradeGroup',
  async (groupData, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.put('createUpdateTradeGroup', groupData);
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const editOptionIcon = createAsyncThunk(
  'binary/editOptionIcon',
  async (optionData, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.put('editBinaryOptionIcon', optionData);
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteOption = createAsyncThunk(
  'binary/deleteOption',
  async (optionID, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.delete('deleteBinaryOption', {
        data: optionID,
      });
      data.id = optionID;
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteTradeGroup = createAsyncThunk(
  'binary/deleteTradeGroup',
  async (groupData, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.delete('deleteTradeGroup', {
        data: groupData,
      });
      data.id = groupData;
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);
