const DashboardIcon = ({ fill, stroke }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.3723 6.875H3.62773C3.0049 6.875 2.5 7.3799 2.5 8.00273V15.7473C2.5 16.3701 3.0049 16.875 3.62773 16.875H16.3723C16.9951 16.875 17.5 16.3701 17.5 15.7473V8.00273C17.5 7.3799 16.9951 6.875 16.3723 6.875Z"
        stroke={stroke}
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path d="M5.625 3.125H14.375ZM4.375 5H15.625Z" fill="#212121" />
      <path
        d="M5.625 3.125H14.375M4.375 5H15.625"
        stroke={stroke}
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
};

const LotteryIcon = ({ fill, stroke }) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="icon-park-outline:stock-market">
        <g id="Group">
          <path
            id="Vector"
            d="M2.5 8.8335H5.83333V14.6668H2.5V8.8335ZM8.33333 6.3335H11.6667V17.1668H8.33333V6.3335Z"
            stroke={stroke}
            strokeLinejoin="round"
          />
          <path
            id="Vector_2"
            d="M10 18.8334V17.1667"
            stroke={stroke}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_3"
            d="M14.167 5.5H17.5003V9.25H14.167V5.5Z"
            stroke={stroke}
            strokeLinejoin="round"
          />
          <path
            id="Vector_4"
            d="M4.16699 8.83341V4.66675M15.8337 14.6667V9.25008M15.8337 5.50008V2.16675"
            stroke={stroke}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </svg>
  );
};

const AdminsIcon = ({ fill, stroke }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.1135 1.87516C5.54402 1.81344 1.81355 5.54391 1.87527 10.1134C1.9362 14.4959 5.50456 18.0642 9.88699 18.1252C14.4573 18.1877 18.187 14.4572 18.1245 9.88766C18.0643 5.50446 14.496 1.9361 10.1135 1.87516ZM15.0518 14.6584C15.0363 14.6752 15.0172 14.6884 14.996 14.697C14.9747 14.7057 14.9519 14.7095 14.929 14.7083C14.9061 14.7071 14.8837 14.7009 14.8635 14.6901C14.8433 14.6793 14.8257 14.6642 14.812 14.6459C14.4626 14.1887 14.0347 13.7974 13.5483 13.49C12.5538 12.8517 11.2936 12.5002 10.0003 12.5002C8.70691 12.5002 7.44675 12.8517 6.45222 13.49C5.96584 13.7972 5.53796 14.1885 5.18855 14.6455C5.17482 14.6638 5.15722 14.6789 5.13701 14.6897C5.11679 14.7005 5.09444 14.7067 5.07155 14.7079C5.04867 14.7091 5.0258 14.7053 5.00457 14.6966C4.98334 14.688 4.96426 14.6748 4.9487 14.658C3.80248 13.4206 3.15319 11.8038 3.12527 10.1173C3.06159 6.31618 6.18074 3.13453 9.98347 3.12516C13.7862 3.11578 16.8753 6.20368 16.8753 10.0002C16.8766 11.7273 16.2253 13.3911 15.0518 14.6584Z"
        fill={fill}
      />
      <path
        d="M10.0003 5.62516C9.22995 5.62516 8.53347 5.91383 8.03855 6.43844C7.54363 6.96305 7.29636 7.68844 7.35222 8.46696C7.4655 10.0002 8.65339 11.2502 10.0003 11.2502C11.3471 11.2502 12.5327 10.0002 12.6483 8.46735C12.7061 7.69625 12.4608 6.9775 11.9577 6.44313C11.4608 5.91579 10.7655 5.62516 10.0003 5.62516Z"
        fill={fill}
      />
    </svg>
  );
};

const ContactIcon = ({ fill, stroke }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5625 3.75H3.4375C2.57456 3.75 1.875 4.44956 1.875 5.3125V14.6875C1.875 15.5504 2.57456 16.25 3.4375 16.25H16.5625C17.4254 16.25 18.125 15.5504 18.125 14.6875V5.3125C18.125 4.44956 17.4254 3.75 16.5625 3.75Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.375 6.25L10 10.625L15.625 6.25"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const PagesIcon = ({ fill, stroke }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 6.25C10.625 3.78281 12.9856 2.52304 18.125 2.5C18.2072 2.49969 18.2886 2.51564 18.3645 2.54694C18.4405 2.57824 18.5095 2.62427 18.5676 2.68236C18.6257 2.74046 18.6718 2.80949 18.7031 2.88545C18.7344 2.96142 18.7503 3.04283 18.75 3.125V14.375C18.75 14.5408 18.6842 14.6997 18.5669 14.8169C18.4497 14.9341 18.2908 15 18.125 15C13.125 15 11.1934 16.0082 10 17.5M10 6.25C9.375 3.78281 7.01446 2.52304 1.875 2.5C1.79284 2.49969 1.71143 2.51564 1.63546 2.54694C1.55949 2.57824 1.49047 2.62427 1.43237 2.68236C1.37427 2.74046 1.32825 2.80949 1.29695 2.88545C1.26565 2.96142 1.24969 3.04283 1.25 3.125V14.2996C1.25 14.6855 1.48907 15 1.875 15C6.875 15 8.81368 16.0156 10 17.5M10 6.25V17.5"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const PlayersIcon = ({ fill, stroke }) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.2621 10.2199C17.5434 6.97069 16.4774 4.89804 14.7688 4.39296C14.4378 4.29643 14.0947 4.24827 13.75 4.24999C12.6996 4.24999 11.8707 4.87499 10 4.87499C8.12931 4.87499 7.29884 4.24999 6.25001 4.24999C5.89093 4.24813 5.53333 4.29625 5.18751 4.39296C3.47657 4.89804 2.41954 6.97265 1.69259 10.2199C0.9504 13.5367 1.08478 16.1574 2.53556 16.6605C3.55118 17.0121 4.45938 16.2851 5.31954 15.2152C6.2961 13.9965 7.49884 13.6215 10 13.6215C12.5012 13.6215 13.657 13.9965 14.6352 15.2152C15.4945 16.2859 16.4363 17.0039 17.425 16.6668C19.0274 16.1195 19.0047 13.5758 18.2621 10.2199Z"
        stroke={stroke}
        strokeMiterlimit="10"
      />
      <path
        d="M11.4062 10.0312C11.8377 10.0312 12.1875 9.68147 12.1875 9.25C12.1875 8.81853 11.8377 8.46875 11.4062 8.46875C10.9748 8.46875 10.625 8.81853 10.625 9.25C10.625 9.68147 10.9748 10.0312 11.4062 10.0312Z"
        fill={fill}
      />
      <path
        d="M13.125 11.75C12.9704 11.75 12.8193 11.7041 12.6908 11.6182C12.5622 11.5323 12.4621 11.4101 12.403 11.2673C12.344 11.1244 12.3286 10.9672 12.3589 10.8156C12.3892 10.664 12.4638 10.5248 12.5733 10.4156C12.6827 10.3065 12.8221 10.2322 12.9738 10.2023C13.1255 10.1724 13.2826 10.1881 13.4253 10.2475C13.568 10.307 13.6899 10.4074 13.7755 10.5361C13.8611 10.6649 13.9066 10.8161 13.9062 10.9707C13.9057 11.1776 13.8232 11.3758 13.6767 11.5219C13.5303 11.668 13.3319 11.75 13.125 11.75Z"
        fill={fill}
      />
      <path
        d="M13.125 8.3125C13.5565 8.3125 13.9062 7.96272 13.9062 7.53125C13.9062 7.09978 13.5565 6.75 13.125 6.75C12.6935 6.75 12.3438 7.09978 12.3438 7.53125C12.3438 7.96272 12.6935 8.3125 13.125 8.3125Z"
        fill={fill}
      />
      <path
        d="M14.8438 10.0312C15.2752 10.0312 15.625 9.68147 15.625 9.25C15.625 8.81853 15.2752 8.46875 14.8438 8.46875C14.4123 8.46875 14.0625 8.81853 14.0625 9.25C14.0625 9.68147 14.4123 10.0312 14.8438 10.0312Z"
        fill={fill}
      />
      <path
        d="M6.25 7.375V11.125M8.125 9.25H4.375"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const TransactionsIcon = ({ fill, stroke }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.25 13.125V1.875L7.5 2.5L8.75 1.875L9.99766 2.5L11.2621 1.875L12.5 2.5L13.7418 1.875L14.9891 2.5L16.25 1.875L17.5004 2.5L18.75 1.875V10.625"
        stroke={stroke}
        strokeLinejoin="round"
      />
      <path
        d="M18.75 10.625V15C18.75 15.8288 18.4208 16.6237 17.8347 17.2097C17.2487 17.7958 16.4538 18.125 15.625 18.125M15.625 18.125C14.7962 18.125 14.0014 17.7958 13.4153 17.2097C12.8293 16.6237 12.5 15.8288 12.5 15V13.125H1.87502C1.79275 13.1243 1.71114 13.1399 1.63499 13.1711C1.55883 13.2022 1.48964 13.2482 1.43146 13.3064C1.37327 13.3646 1.32726 13.4338 1.29611 13.51C1.26496 13.5861 1.24929 13.6677 1.25002 13.75C1.25002 16.25 1.51331 18.125 4.37502 18.125H15.625Z"
        stroke={stroke}
        strokeLinejoin="round"
      />
      <path
        d="M8.75 5.625H16.25M11.25 8.75H16.25"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const NavIcon = ({ type, active = false }) => {
  const color = active ? '#f7f7f7' : '#212121';
  if (type === 'dashboard') {
    return <DashboardIcon fill={color} stroke={color} />;
  } else if (type === 'binary option') {
    return <LotteryIcon fill={color} stroke={color} />;
  } else if (type === 'admins') {
    return <AdminsIcon fill={color} stroke={color} />;
  } else if (type === 'contact') {
    return <ContactIcon fill={color} stroke={color} />;
  } else if (type === 'pages content') {
    return <PagesIcon fill={color} stroke={color} />;
  } else if (type === 'traders') {
    return <PlayersIcon fill={color} stroke={color} />;
  } else if (type === 'transactions') {
    return <TransactionsIcon fill={color} stroke={color} />;
  }

  return <span className="ml-[20px]"></span>;
};

export default NavIcon;
