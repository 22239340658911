import { useState } from 'react';
import ActiveWalletInfo from '../Modal/ActiveWalletInfo';
import { useSelector } from 'react-redux';
import WalletCard from './WalletCard';
import SecondaryButton from '../Button/SecondaryButton';
import ConnectWallet from './ConnectWallet';
import WalletList from './WalletList';

const ActiveWallet = ({ showWithdrawNote = false }) => {
  const [modal, setModal] = useState(false);
  const { activeWallet, walletList } = useSelector((state) => state.wallet);

  return (
    <>
      {modal === 'tooltip' ? (
        <ActiveWalletInfo closeModal={setModal} />
      ) : modal === 'connect_wallet' ? (
        <ConnectWallet closeModal={setModal} />
      ) : modal === 'wallet_list' ? (
        <WalletList closeModal={setModal} />
      ) : null}
      <div className="flex flex-col mb-[16px]">
        <div className="text-sm flex flex-row items-center">
          <p>
            Your <span className="font-poppins-semibold"> Active </span>{' '}
            External Wallet Address{' '}
          </p>
          <button
            type="button"
            className="flex ml-[4px]"
            onClick={() => setModal('tooltip')}
          >
            <ion-icon
              name="information-circle-outline"
              style={{ width: '16px', height: '16px' }}
            ></ion-icon>
          </button>
        </div>
        {walletList.length && activeWallet ? (
          <WalletCard
            walletInfo={activeWallet}
            canEdit={false}
            showWalletList={setModal}
          />
        ) : (
          <SecondaryButton
            title="Connect Your Wallet Address"
            classes="mt-[4px] !bg-black-button text-white"
            onClick={() => setModal('connect_wallet')}
          />
        )}
      </div>
      {activeWallet && showWithdrawNote ? (
        <p className="text-sm opacity-50 mt-[4px] mb-[16px]">
          <span className="font-poppins-semibold">Note: </span>
          This will withdraw CKU to your{' '}
          <span className="font-poppins-semibold">Latoken</span> account (
          {activeWallet.wallet_address}). Please make sure this is the correct
          address you would like to withdraw to.
        </p>
      ) : null}
    </>
  );
};

export default ActiveWallet;
