import { useEffect, useState } from 'react';
import LayoutAdmin from '../components/Layout/LayoutAdmin';
import Loading from '../components/Common/Loading';
import { TableBody, TableHead, TableRow } from '@mui/material';
import StyledTableContainer from '../components/Table/StyledTableContainer';
import StyledTable from '../components/Table/StyledTable';
import StyledTableCell from '../components/Table/StyledTableCell';
import ContactNewModal from '../components/Modal/ContactNewModal';
import { useDispatch, useSelector } from 'react-redux';
import { fetchContactList } from '../actions/contact';

const ContactPage = () => {
  const { isLoading, contactList } = useSelector((state) => state.contact);
  const [showContactForm, setShowContactForm] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchContactList());
  }, [dispatch]);

  return (
    <>
      {showContactForm ? (
        <ContactNewModal
          closeModal={setShowContactForm}
          selectedContact={showContactForm}
        />
      ) : null}
      {isLoading ? <Loading /> : null}
      <LayoutAdmin pageSubtitle="Contact" pageTitle="Contact">
        <div className="flex flex-row w-[100%] lg:w-[70%] items-center justify-between mb-[21px]">
          <h3 className="font-poppins-bold text-base">Contact Platform</h3>
          <button
            onClick={() => setShowContactForm(true)}
            className="bg-white px-[16px] py-[4px] border border-black-100 rounded-[20px] font-poppins-semibold"
          >
            New Contact
          </button>
        </div>
        {contactList && contactList.length ? (
          <StyledTableContainer sx={{ width: ['100%', '70%', '70%'] }}>
            <StyledTable>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Icon</StyledTableCell>
                  <StyledTableCell>Platform Name</StyledTableCell>
                  <StyledTableCell>Link</StyledTableCell>
                  <StyledTableCell sx={{ textAlign: 'center' }}>
                    Action
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {contactList.map((contact) => (
                  <TableRow key={contact.link}>
                    <StyledTableCell>
                      <div className="w-[46px] h-[46px] bg-black-100">
                        <img
                          src={contact.icon}
                          alt="contact icon"
                          className="w-full h-full object-contain"
                        />
                      </div>
                    </StyledTableCell>
                    <StyledTableCell>{contact.name}</StyledTableCell>
                    <StyledTableCell>{contact.link}</StyledTableCell>
                    <StyledTableCell sx={{ textAlign: 'center' }}>
                      <button
                        onClick={() => setShowContactForm(contact)}
                        className="font-poppins-semibold py-[4px] px-[16px] border rounded-[20px] border-black-100"
                      >
                        Edit
                      </button>
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </StyledTable>
          </StyledTableContainer>
        ) : (
          <p>No Contact data found!</p>
        )}
      </LayoutAdmin>
    </>
  );
};

export default ContactPage;
