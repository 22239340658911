import axios from 'axios';

const baseURL =
  process.env.REACT_APP_API === 'production'
    ? 'https://admin.duotrx.com/api/'
    : 'https://staging.admin.duotrx.com/api/';

const serverAPI = axios.create({
  baseURL,
});

serverAPI.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('USER_KEY');
    if (token) config.headers.Authorization = `Bearer ${token}`;
    config.timeout = 120000;
    return config;
  },
  (error) => Promise.reject(error)
);

serverAPI.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        localStorage.removeItem('USER_KEY');
        window.location.assign(window.location);
      }
      return Promise.reject(error.response.data);
    }
  }
);

const cancelToken = axios.CancelToken;

export { serverAPI, cancelToken, baseURL };
