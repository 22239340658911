import { Backdrop } from '@mui/material';
import Input from '../Input/Input';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { changePassword } from '../../utils/validationForm';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changePasswordAdmin } from '../../actions/admin';
import getToastMessage from '../../utils/toastMessage';
import { enqueueSnackbar } from 'notistack';
import { setUserInfo } from '../../slices/authSlice';

const AdminChangePassword = () => {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState,
    setError,
    clearErrors,
  } = useForm({
    resolver: yupResolver(changePassword),
  });
  const watchPass = watch('new_password', '');
  const watchConfirmPass = watch('confirm_new_password', '');

  const [showForm, setShowForm] = useState(false);
  const userInfo = useSelector((state) => state.auth.userInfo);
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    console.log('submitted form');
    const done = await dispatch(changePasswordAdmin(data));
    if (done) {
      console.log(done, '====done');
      const { message, variant } = getToastMessage(done);
      if (variant === 'success') {
        const newUserInfo = JSON.parse(JSON.stringify(userInfo));
        newUserInfo.has_temporary_password = false;
        dispatch(setUserInfo(newUserInfo));
        enqueueSnackbar(message, { variant });
      }
    }
  };

  useEffect(() => {
    if (userInfo && userInfo.has_temporary_password) {
      setShowForm(true);
    } else {
      setShowForm(false);
    }
  }, [userInfo]);

  useEffect(() => {
    if (watchConfirmPass !== watchPass) {
      setError('confirm_new_password', {
        type: 'custom',
        message: "Password doesn't match",
      });
    } else if (watchConfirmPass === watchPass) {
      clearErrors('confirm_new_password');
    }
  }, [clearErrors, setError, watchConfirmPass, watchPass]);

  return (
    <>
      <Backdrop sx={{ zIndex: 1500 }} open={showForm} className="flex flex-col">
        <div className="w-[85%] lg:w-[25%] absolute z-100 bg-white-f p-[24px] rounded-lg shadow-modal top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <div className="flex flex-row justify-between items-center border-b border-b-grey-20 pb-[24px]">
            <h3 className="text-lg font-poppins-bold">Input New Password</h3>
          </div>
          <section className="mt-[24px]">
            <form onSubmit={handleSubmit(onSubmit)} name="change password">
              <Input
                name="old_password"
                placeholder="Password"
                title="Temporary Password"
                type="password"
                register={register}
                errors={formState.errors}
              />
              <Input
                name="new_password"
                placeholder="Password"
                title="New Password"
                register={register}
                type="password"
                errors={formState.errors}
              />
              <Input
                name="confirm_new_password"
                placeholder="Password"
                title="Confirm Password"
                register={register}
                type="password"
                errors={formState.errors}
              />
              <div className="flex flex-row justify-between items-center mt-[24px]">
                <button
                  type="button"
                  onClick={() => reset()}
                  className="w-[45%] px-[16px] py-[8px] text-red border rounded-[20px] font-poppins-semibold"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="w-[45%] px-[16px] py-[8px] bg-black-100 text-white border rounded-[20px] font-poppins-semibold"
                >
                  Save
                </button>
              </div>
            </form>
          </section>
        </div>
      </Backdrop>
    </>
  );
};

export default AdminChangePassword;
