import { useDispatch, useSelector } from 'react-redux';
import LayoutAdmin from '../components/Layout/LayoutAdmin';
import FAQ from '../components/Content/FAQ';
import { useEffect } from 'react';
import { fetchContentList } from '../actions/content';
import ContentWYSIWYG from '../components/Content/ContenWYSIWYG';

const ContentPage = () => {
  const selectedSection = useSelector((state) => state.content.selectedSection);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchContentList({ content_type: selectedSection }));
  }, [dispatch, selectedSection]);

  return (
    <LayoutAdmin pageSubtitle="Pages Content" pageTitle="Pages Content">
      {selectedSection === 'FAQ' ? <FAQ /> : <ContentWYSIWYG />}
    </LayoutAdmin>
  );
};

export default ContentPage;
