import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../components/Common/Loading';
import LayoutAdmin from '../components/Layout/LayoutAdmin';
import { useEffect, useState } from 'react';
import { fetchRecruiterDetail } from '../actions/players';
import { setMemberPageData, setTicketPageData } from '../slices/playersSlice';
import EditPlayerModal from '../components/Player/EditPlayerModal';
import moment from 'moment';
import { thousandSeparator } from '../utils/math';
import StyledTable from '../components/Table/StyledTable';
import StyledTableContainer from '../components/Table/StyledTableContainer';
import StyledTableCell from '../components/Table/StyledTableCell';
import { TableBody, TableHead, TableRow } from '@mui/material';
import Pagination from '../components/Table/Pagination';
import { enqueueSnackbar } from 'notistack';
import DataLimit from '../components/Table/DataLimit';

const RecruiterDetailPage = () => {
  const {
    isLoading,
    selectedRecruiterDetail,
    recruiterMemberList,
    memberPages,
    memberPageData,
  } = useSelector((state) => state.players);
  const [editModal, setEditModal] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const fields = [
    {
      placeholder: 'Trader ID',
      field: 'player_id',
    },
    {
      placeholder: 'Trader Status',
      field: 'status',
    },
    {
      placeholder: 'Registered Date',
      field: 'createdAt',
    },
    {
      placeholder: 'CKU Balance',
      field: 'cku_balance',
    },
    {
      placeholder: 'Phone',
      field: 'phone_number',
    },
    {
      placeholder: 'Amount Purchase',
      field: 'total_trades',
    },
    {
      placeholder: 'Win History',
      field: 'winning_trades',
    },
    {
      placeholder: 'Referral Link',
      field: 'referral_tag',
    },
  ];

  useEffect(() => {
    const params = { id, ...memberPageData };
    dispatch(fetchRecruiterDetail(params));
  }, [dispatch, id, memberPageData]);

  const onSearchMember = (ev) => {
    if (ev.key === 'Enter' && ev.target.value !== memberPageData.search) {
      const search = ev.target.value;
      dispatch(setMemberPageData({ search }));
    }
  };

  const goToPlayerDetail = (id) => {
    navigate(`/traders/${id}`);
  };

  const handlePageChange = (ev) => {
    if (ev.selected !== memberPageData.page) {
      dispatch(setMemberPageData({ page: ev.selected }));
    }
  };

  return (
    <>
      {isLoading ? <Loading /> : null}
      {editModal ? (
        <EditPlayerModal closeModal={setEditModal} playerDetail={editModal} />
      ) : null}
      <LayoutAdmin
        pageTitle="Recruiters"
        pageSubtitle="Recruiters, Recruiter Detail"
      >
        {selectedRecruiterDetail ? (
          <div>
            <div className="flex flex-row items-center justify-between lg:justify-start font-poppins-semibold">
              <h3 className="text-base">Player Detail</h3>
              <button
                type="button"
                onClick={() => {
                  setEditModal({
                    _id: selectedRecruiterDetail._id,
                    player_id: selectedRecruiterDetail.player_id,
                    status: selectedRecruiterDetail.status,
                    email: selectedRecruiterDetail.email,
                    phone_number: selectedRecruiterDetail.phone_number,
                  });
                }}
                className="px-[16px] py-[6px] border border-black-100 bg-white rounded-[20px] lg:ml-[15%]"
              >
                Edit Trader
              </button>
            </div>
            <div className="mt-[16px] pb-[8px] border-b border-b-grey-20">
              {fields.map((field, idx) => (
                <div
                  className="flex flex-row items-center w-full mb-[8px] text-sm"
                  key={idx}
                >
                  <label className="w-[45%] lg:w-[25%] font-poppins-semibold">
                    {field.placeholder}
                  </label>
                  <p
                    className={
                      field.field === 'total_trades' ||
                      field.field === 'winning_trades'
                        ? 'underline cursor-pointer'
                        : ''
                    }
                    onClick={() => {
                      if (
                        field.field === 'total_trades' ||
                        field.field === 'winning_trades'
                      ) {
                        const url = encodeURI(
                          `/traders/${selectedRecruiterDetail.player_id}?player_section=Trading History`
                        );
                        navigate(url);
                        if (field.field === 'winning_trades') {
                          dispatch(setTicketPageData({ ticket_status: 'Win' }));
                        }
                      }
                    }}
                  >
                    {field.field === 'createdAt'
                      ? moment(selectedRecruiterDetail.createdAt).format(
                          'DD-MM-YYYY, HH:mm:ss'
                        )
                      : field.field === 'winning_trades'
                      ? selectedRecruiterDetail[field.field].length
                      : field.field === 'cku_balance'
                      ? `CKU ${thousandSeparator(
                          selectedRecruiterDetail[field.field]
                        )}`
                      : selectedRecruiterDetail[field.field]}
                  </p>
                  {field.field === 'referrer_tag' &&
                  selectedRecruiterDetail[field.field] ? (
                    <button
                      onClick={() => {
                        navigator.clipboard.writeText(
                          selectedRecruiterDetail.referrer_tag
                        );
                        enqueueSnackbar('Sucessfully copied referral link', {
                          variant: 'success',
                        });
                      }}
                      className="flex ml-[5px]"
                    >
                      <ion-icon
                        name="copy-outline"
                        style={{ width: '18px', height: '18px' }}
                      ></ion-icon>
                    </button>
                  ) : null}
                </div>
              ))}
            </div>
            <div className="mt-[16px] flex flex-col">
              <h3 className="text-base font-poppins-semibold">
                List of Recruited Member
              </h3>
              {recruiterMemberList && recruiterMemberList.length ? (
                <div>
                  <div className="flex flex-col my-[16px]">
                    <label className="text-sm ">Search</label>
                    <input
                      placeholder="Player ID"
                      onKeyDown={onSearchMember}
                      className="bg-white w-full lg:w-1/3 p-[8px] border border-black-100 rounded-md text-sm outline-none"
                    />
                  </div>
                  <DataLimit
                    initialLimit={memberPageData.dataLimit}
                    setter={setMemberPageData}
                    pageState="member_page"
                    classList="mb-[16px]"
                  />
                  <StyledTableContainer sx={{ width: ['100%', '50%', '50%'] }}>
                    <StyledTable>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>Player ID</StyledTableCell>
                          <StyledTableCell>Amount of Purchase</StyledTableCell>
                          <StyledTableCell>Amount of Win</StyledTableCell>
                          <StyledTableCell>Action</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {recruiterMemberList.map((member) => (
                          <TableRow key={member._id}>
                            <StyledTableCell>
                              {member.player_id}
                            </StyledTableCell>
                            <StyledTableCell>
                              {thousandSeparator(member.total_trades)}
                            </StyledTableCell>
                            <StyledTableCell>
                              {thousandSeparator(member.winning_trades)}
                            </StyledTableCell>
                            <StyledTableCell>
                              <button
                                onClick={() =>
                                  goToPlayerDetail(member.player_id)
                                }
                                className="font-poppins-semibold py-[4px] px-[16px] border rounded-[20px] border-black-100"
                              >
                                Detail
                              </button>
                            </StyledTableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </StyledTable>
                  </StyledTableContainer>
                  <Pagination
                    totalPages={memberPages}
                    pageData={{ page: memberPageData.member_page }}
                    onChange={handlePageChange}
                  />
                </div>
              ) : (
                <p className="text-sm">No recruited members data found!</p>
              )}
            </div>
          </div>
        ) : (
          <p>Player data not found!</p>
        )}
      </LayoutAdmin>
    </>
  );
};

export default RecruiterDetailPage;
